.portfolio-section.wip{
	height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.wip-back {
	line-height: 1;
	font-family: $font-family-montserrat;
	font-weight: $font-semibold;
	font-size: 1.5625rem;
	color: $clr-green;
	margin-bottom: 1rem;
}

h2.wip-text {
	color: $clr-white;
	font-family: $font-family-montserrat;
	font-weight: $font-semibold;
	font-size: 3.25rem;
}

.MuiGrid-root.MuiGrid-item.wip-div {
	margin-top: -5.3125rem;
	margin-left: 10em;
}

img.wip-img2 {
	width: 40%;
}

.MuiGrid-root.MuiGrid-item.wip-div2 {
	margin-top: 13em;
	margin-left: -1.5em;
}
