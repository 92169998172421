.about-section {
	position: relative;
	padding-top: 6.25rem;
	background-color: $clr-bg-main;
}

.about-section .inner-container {
	position: relative;
	/* padding-bottom:4.375rem; */
}
.sec-title.term h2 {
	margin-top: 1.875rem;
}

.about-column.term {
	margin-bottom: 3.75rem;
}

/*ol ul type*/
li.rp-list {
	margin-bottom: 1.25em;
}

ol.ol-type-number {
	counter-reset: item;
	margin-left: 3.125rem;
	margin-top: 1.25em;
	color: $clr-text-one;
	font-size: 1.125rem;
	font-family: $font-family-montserrat;
}

ol.ol-type-number>li:before {
	counter-increment: item;
	/* content: counter(item, decimal) ") "; */
	position: absolute;
	left: -0.6em;
	color: $clr-white;
	background-color: $clr-pink;
	border-radius: 1.25rem;
	padding-left: 0.6875rem;
}

ol.ol-type-number1 {
	counter-reset: item;
	margin-left: 2.8125rem;
	margin-top: 1.25em;
	font-size: 1.0625rem;
	font-family: $font-family-opensans;
	font-weight: $font-regular;
	color: $clr-text-one;
	line-height: 1.625rem;
}

ol.ol-type-number1>li:before {
	counter-increment: item;
	content: counter(item, decimal) ") ";
	position: absolute;
	left: -0.6em;
	color: $clr-text-one;
	border-radius: 1.25rem;
	padding-left: 0rem;
}

.term ol.ol-type-small {
	counter-reset: list;
	margin-left: 2.8125rem;
	margin-top: 1.25em;
	font-size: 1.0625rem;
	font-family: $font-family-opensans;
	font-weight: $font-regular;
	color: $clr-text-one;
	line-height: 1.625rem;
}

ol.ol-type-small>li:before {
	counter-increment: list;
	content: counter(list, lower-alpha) ") ";
	position: absolute;
	left: -0.6em;
	color: $clr-text-one;
}

.term ol.ol-type-roman {
	counter-reset: list;
	margin-left: 2.8125rem;
	margin-top: 1.25em;
	font-size: 1.0625rem;
	font-family: $font-family-opensans;
	font-weight: $font-regular;
	color: $clr-text-one;
	line-height: 1.625rem;
}

ol.ol-type-roman>li:before {
	counter-increment: list;
	content: counter(list, lower-roman) ") ";
	position: absolute;
	left: -0.9375rem;
	color: $clr-text-one;
}

ol.ol-type-capital {
	list-style: upper-alpha;
	text-align: justify;
}

ol li::before {
	content: "\A";
	color: $clr-text-one;
	font-weight: bold;
	display: inline-block;
	width: 1em;
	margin-left: -1.2em;
}

ol>li {
	list-style: none;
	position: relative;
}

ol.ol-type-small li::marker {
	font-weight: $font-semibold;
}

ol.ol-type-number li::marker {
	font-weight: $font-semibold;
}

ol.ol-type-capital li::marker {
	font-weight: $font-semibold;
}

ol.double-number {
	counter-reset: item
}

li.double-number {
	display: block
}

li.double-number:before {
	content: counters(item, ".") " ";
	counter-increment: item;
	width: 1.8em !important;
	font-weight: 600 !important;
	margin-left: -2em !important;
}

a.link-color {
	color: $clr-green;
    text-decoration: none;
}

a.link-color:hover {
	color: $clr-green;
}

b,
strong {
	font-weight: $font-semibold;
}

ol.ol-type-number>li.dnum:before {
	padding-left: 0.3125rem;
}

h2.term-privacy {
	font-size: 2.25rem !important;
}
.sec-title h2 {
	position: relative;
	color: $clr-text-ten;
	font-weight: $font-semibold;
	line-height: 3.6875rem;
	margin-top: 2.5rem;
	font-family: $font-family-montserrat;
	font-size: 3.25rem;
}
p.term-desc {
	padding: 0.9375rem 0rem 0;
	font-size: 1.0625rem;
	font-family: $font-family-opensans;
	font-weight: $font-regular;
	color: $clr-text-one;
	line-height: 1.625rem;
    margin-bottom: 0;
}