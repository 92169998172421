li a.theme-btn.btn-style-one.get-started {
 text-transform: none;
 border-radius: 1.875rem;
 background-color: $clr-pink;
 color: $clr-white;
 width: auto;
 padding: 0;
 display: inline-grid;
 font-weight: $font-bold;
 font-size: 1.25rem;
 font-family: $font-family-opensans;
 top: 1rem;
}

li a.theme-btn.btn-style-one.get-started .txt {
 padding: 0.5rem 1.25rem;
}

a.theme-btn.btn-style-one.get-started {
 text-transform: none;
 border-radius: 1.875rem;
 background-color: $clr-white;
 color: $clr-pink;
 width: auto;
 padding: 0.5rem 1.25rem;
 display: inline-grid;
 font-weight: $font-bold;
 text-align: center;
 font-size: 1.2rem;
 font-family: $font-family-opensans;
 line-height: 1.4375rem;
 text-decoration: none;
}

a.theme-btn.btn-style-one.get-started:hover {
 color: $clr-white;
}

/*Btn Style One*/

.btn-style-one {
 position: relative;
 line-height: 1;
 overflow: hidden;
 text-transform: none;
 border-radius: 1.875rem;
 background-color: $clr-pink;
 color: $clr-white;
 width: auto;
 padding: 0.5625rem 1.25rem;
 display: inline-grid;
 font-weight: $font-bold;
 font-size: 1.125rem;
 font-family: $font-family-opensans;
 cursor: pointer;
}

.btn-style-one:before {
 position: absolute;
 content: "";
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 z-index: 1;
 opacity: 0;
 background-color: $clr-green;
 -webkit-transition: all 0.4s;
 -moz-transition: all 0.4s;
 -o-transition: all 0.4s;
 transition: all 0.4s;
 -webkit-transform: scale(0.2, 1);
 transform: scale(0.2, 1);
}

.btn-style-one i {
 position: relative;
 top: 0.0625rem;
 margin-left: 0.25rem;
}

.btn-style-one .txt {
 position: relative;
 z-index: 1;
}

.btn-style-one:hover::before {
 opacity: 1;
 -webkit-transform: scale(1, 1);
 transform: scale(1, 1);
}

.btn-style-one:hover {
 color: $clr-white;
}

button.theme-btn.btn-style-one.get-started {
 background-color: $clr-pink;
 box-shadow: none !important;
}

.btn {
 border-radius: 1.5625rem !important;
 transition: none !important;
 box-shadow: none !important;
 font-family: $font-family-opensans;
 font-size: 1.0625rem !important;
 font-weight: 700 !important;
 height: 2.625rem !important;
 padding: 0.5rem 1.25rem;
 margin: 0rem 0.9375rem 1.25rem;
}

.btnjwt {
 border-radius: 1.5625rem !important;
 transition: none !important;
 box-shadow: none !important;
 font-family: "Open Sans", sans-serif;
 font-size: 1.3rem !important;
 font-weight: 600 !important;
 height: 2.625rem !important;
 padding: 0.5rem 1.25rem;
 margin: 3rem -0.0625rem 1.25rem;
 color: #3a67e1;
}

.btn:disabled,
.btn:disabled:hover,
.btn:disabled:active,
.btn:disabled:hover:active::before {
 background-color: $clr-bg-button-disabled;
 box-shadow: unset !important;
}

.download-btn-align {
 margin-top: -0.7rem !important;
}

.txt.mb-factbtn {
 font-size: 1rem !important;
}
