/* footer */

.main-footer {
 /* position: relative; */
 //  background-color: $clr-bg-white;
 z-index: 1;
 background-color: #1a1e25;
 color: #e8e8e8;
 padding-top: 2em;
 /* left: 0rem;
    top: 0rem;
    right: 0rem;
    bottom: 0rem; */
 box-shadow: $clr-top-box-shadow;
 font-family: "Open Sans", sans-serif;
}

.main-footer.style-two {
 border-top: 0.0625rem solid $clr-text-two;
}

.main-footer .footer-widget {
 position: relative;
 margin-bottom: 1.875rem;
 margin-top: 1.25rem;
 // margin-bottom: 0;
 // margin-top: 0;
}

.main-footer .widgets-section {
 position: relative;
 padding: 1.25rem 0rem 0rem;
}

.main-footer .footer-widget h5 {
 position: relative;
 font-weight: $font-bold;
 color: $clr-text-one;
 line-height: 1.75rem;
 margin-top: 1.5625rem;
 margin-bottom: 1.5625rem;
 text-transform: capitalize;
 font-size: 0.875rem;
 font-family: $font-family-opensans;
}

.main-footer .logo-widget {
 position: relative;
}

.main-footer .logo-widget .logo {
 position: relative;
 margin-bottom: 0rem;
}

.main-footer .logo-widget .call {
 position: relative;
 color: $clr-text-nine;
 font-size: 0.875rem;
 font-weight: $font-regular;
 line-height: 1.8em;
 text-transform: uppercase;
}

.main-footer .logo-widget .call .phone {
 position: relative;
 color: $clr-black;
 font-size: 1.5rem;
 display: block;
 margin-top: 0.9375rem;
 margin-bottom: 0.4375rem;
 transition: all 500ms ease;
 -moz-transition: all 500ms ease;
 -webkit-transition: all 500ms ease;
 -ms-transition: all 500ms ease;
 -o-transition: all 500ms ease;
}

.main-footer .logo-widget .call .email {
 position: relative;
 color: $clr-black;
 font-size: 1rem;
 display: block;
 margin-top: 0rem;
 text-transform: capitalize;
 transition: all 500ms ease;
 -moz-transition: all 500ms ease;
 -webkit-transition: all 500ms ease;
 -ms-transition: all 500ms ease;
 -o-transition: all 500ms ease;
}

.main-footer .logo-widget .call .phone:hover,
.main-footer .logo-widget .call .email:hover {
 color: $clr-hyperlink;
}

/* List Link */

.list-link {
 position: relative;
 list-style: none;
}

.list-link li {
 position: relative;
 margin-bottom: 0.5rem;
}

.list-link li a {
 position: relative;
 color: $clr-text-one;
 font-size: 0.875rem;
 font-weight: $font-regular;
 font-family: $font-family-opensans;
 line-height: 1.75rem;
 text-decoration: none;
 -webkit-transition: all 300ms ease;
 -ms-transition: all 300ms ease;
 -o-transition: all 300ms ease;
 -moz-transition: all 300ms ease;
 transition: all 300ms ease;
}

ul.list-link {
 padding-left: 0;
}

.list-link li a:hover {
 color: $clr-green;
}

.main-footer .newsletter-widget .text {
 position: relative;
 color: $clr-text-three;
 font-size: 0.8125rem;
 line-height: 1.125rem;
 margin-bottom: 0.9375rem;
 margin-top: 1.25rem;
 font-family: $font-family-opensans;
 font-weight: $font-regular;
}

.mobile-footer {
 display: none;
}
p.copyright {
 text-align: center;
 padding: 0.625rem 0;
 color: #e8e8e8;
 font-size: 0.875rem;
 font-weight: $font-bold;
 font-family: $font-family-opensans;
 line-height: 1.75rem;
 border-top: 0.0625rem solid #dbdbdb;
}
a.icofont-ui-call {
 border: 0.0625rem solid $clr-text-one;
 border-radius: 3.125rem;
 padding: 0.625rem;
 margin-right: 0.9375rem;
}

a.icofont-envelope {
 border: 0.0625rem solid $clr-text-one;
 border-radius: 3.125rem;
 padding: 0.625rem;
 margin-right: 0.9375rem;
}
.foot-logo {
 position: relative;
 width: 100%;
}
p.broker-ftext {
 font-size: 13px;
 line-height: 1.8;
}
.hr {
 border: 1px solid #353c46;
 margin: 30px 0;
 height: 1px;
}
.footer-details p {
 margin: 0px 0 30px;
}
.footer-details .col-lg-4.l-float {
 width: 33.33%;
}
.footer-widget.links-widget p {
 line-height: 2;
}
.footer-widget.links-widget p strong {
 font-weight: 700 !important;
}
.clr-link {
 color: #1dceb2;
}
