/*Media Query for all screens*/
@media (min-width: 1080px) and (max-width: 1920px) {
 .mr-12 {
  margin-right: 1.5625rem !important;
 }

 .mr-2 {
  margin-right: 1.0625rem !important;
 }

 .mr-26 {
  margin-right: 2.5rem !important;
 }
}

@media (min-width: 768px) and (max-width: 1366px) {
 /* button.search-icon.advisorsearch{left: 88%;}
	button.search-icon{left: 91%;} */
 .vline3 {
  left: 49.5%;
 }

 /* .broker-margin {
		margin: 2.5rem 0rem 3.125rem -3.125rem;
	} */

 .modal-main-front.popup-submit.risk-profile {
  width: 61%;
 }

 .form-group-checkbox.mb-checkbox {
  margin: 0.9375rem 0.9375rem 0rem;
 }

 .border-splan.mb-border-splan {
  width: 57%;
  margin: -0.5rem 6.375rem !important;
 }

 .mr-12 {
  margin-right: 4.9375rem !important;
 }

 .mr-2 {
  margin-right: 4.375rem !important;
 }

 .mr-26 {
  margin-right: 5.75rem !important;
 }

 .subscription-container {
  padding: 0 0.625rem;
  margin-left: 3.75rem !important;
 }

 .modal-main-front.popup-submit {
  width: 54.2%;
  /* top: 54%; */
 }

 // img.mb-gwlogo {
 // 	width: 10.625rem;
 // }

 .mobile-border-front {
  border-top: 0.0625rem solid #797677;
  margin: 0.625rem 0.3125rem 1.875rem 0.9375rem;
  width: 45%;
 }

 label.sub-plan.mb-sub-plan.reso-normal {
  margin-top: -0.3125rem !important;
  margin-left: -0.3125rem;
 }

 .form-group-checkbox.mb-checkbox label:before {
  margin-top: -0.625rem;
 }

 .form-group-checkbox.mb-checkbox input:checked + label:after {
  left: 0.6875rem;
  top: -0.5625rem;
 }

 /* .portfolio-section.loader {
		padding: 24.45em;
	} */

 .square {
  width: 1.9375rem;
  height: 1.9375rem;
  left: 1.875rem;
  top: -0.1875rem;
 }
 .triangle {
  top: -0.125rem;
 }
 .inner-box.portfolio.dashboard-port.mb-dashboard-port {
  width: 22.5rem;
  margin: 0rem 0.625rem 0rem;
 }
 .portfolio-border-top p.port-desc.dashboard.mb-dashboard {
  margin-left: 0.3125rem;
 }
 p.portfolio-date.dashboard.mb-dashboard {
  margin-left: -1.875rem !important;
 }
 p.port-desc.advisor-name.dashboard.mb-dashboard {
  margin-left: 1.25rem !important;
 }
 .portfolio-border-top p.port-desc.dashboard.portfolio {
  margin-left: 0.625rem;
 }
}

@media (min-width: 1050px) and (max-width: 1680px) {
 /* .broker-margin {
		margin: 2.5rem 0rem 3.125rem -0.75rem;
	} */

 .vline3 {
  left: 50.6%;
 }

 .carousel .slider-wrapper {
  width: 84% !important;
 }

 .subscription-container {
  padding: 0 0;
 }

 .modal-main-front.popup-submit.risk-profile {
  width: 61%;
 }

 .mr-12 {
  margin-right: 2.75rem;
 }

 .mr-2 {
  margin-right: 2.125rem;
 }

 .mr-26 {
  margin-right: 3.75rem;
 }

 // img.mb-gwlogo {
 // 	width: 10.625rem;
 // }
}

@media (min-width: 900px) and (max-width: 1600px) {
 /* .broker-margin {
		margin: 2.5rem 0rem 3.125rem -1.875rem;
	} */

 .carousel .slider-wrapper {
  width: 83% !important;
 }

 .mr-12 {
  margin-right: 2.125rem;
 }

 .mr-2 {
  margin-right: 1.5rem;
 }

 .mr-26 {
  margin-right: 3rem;
 }

 // img.mb-gwlogo {
 // 	width: 10.625rem;
 // }
}

@media (min-width: 900px) and (max-width: 1440px) {
 .modal-main-front.popup-submit.risk-profile {
  width: 50%;
 }

 /* .broker-margin {
		margin: 2.5rem 0rem 3.125rem -3.125rem;
	} */

 .carousel .slider-wrapper {
  width: 84% !important;
 }

 .mr-12 {
  margin-right: 1.1875rem;
 }

 .mr-2 {
  margin-right: 0.75rem;
 }

 .mr-26 {
  margin-right: 2.1875rem;
 }

 label.sub-plan.mb-sub-plan.reso-normal {
  margin-top: 0.625rem;
 }
}

@media (min-width: 1050px) and (max-width: 1400px) {
 .modal-main-front.popup-submit.risk-profile {
  width: 61%;
 }
}

@media (min-width: 1024px) and (max-width: 1280px) {
 .modal-main-front.popup-submit.risk-profile {
  width: 56%;
 }
}

/*Media Query for all screens*/

/*Media Query for mobile responsive*/

/* Device = Tablets, Ipads (portrait) */
@media (min-width: 768px) and (max-width: 1024px) {
//  h2.faq-heading {
//   margin: 1.5rem 1rem 1.5rem !important;
//  }
//  .tabs {
//   margin: 20px;
//  }

 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.dashboard-top {
  overflow: scroll;
 }
 .dash-order-width.ipad-order-width {
  width: 90% !important;
 }
 .dash-btn-center {
  left: 50% !important;
 }


 button.theme-btn.btn-style-one.get-started.btn-w160.btn-dash {
  padding: 0.5625rem 0.25rem;
  font-size: 14px !important;
  min-width: 120px !important;
  width: 120px !important;
 }

 .inner-box.portfolio.dashboard-port.mb-dashboard-port.mobile-apierror {
  width: 500px !important;
  margin: 0 auto;
 }
 .portfolio-section.portfolio-sec-details.mb-psd {
  min-height: 48vh;
 }
 .invest-container.mb-investcont {
  display: flex;
 }
 #menu {
  margin: -6.25rem 0 0 -44em !important;
  width: 110%;
 }
 img.mobile-logo {
  margin-top: 0.9375rem;
  width: 7.5rem !important;
 }
 .signin-mbmenu {
  margin-top: 1.0625rem !important;
  text-align: right;
 }
 /* #menuToggle{top: 1.8125rem;} */
 #menuToggle {
  left: 5.625rem !important;
 }
 .signin-mbmenu span {
  font-weight: $font-bold;
  font-size: 0.9375rem;
  margin-right: -4.0625rem;
 }
 .subscription-container {
  margin-left: -4.375rem !important;
 }
 li a.theme-btn.btn-style-one.get-started .txt {
  padding: 0.5rem 0.25rem !important;
  font-size: 0.875rem !important;
 }
 .logo a img {
  width: 70%;
 }
 li a.theme-btn.btn-style-one.get-started.login-letter {
  width: 3.6875rem !important;
 }
 .main-menu .navigation > li {
  margin-right: 1.25rem !important;
  font-size: 0.875rem !important;
 }
 .main-menu .navigation > li > a {
  font-size: 0.875rem !important;
 }
 .mobilemenu {
  display: none;
 }
 .desktopmenu {
  display: block !important;
 }
 .mobile-footer {
  display: block !important;
 }
 .desktop-footer {
  display: none;
 }
 li a.theme-btn.btn-style-one.get-started {
  width: 7rem !important;
  color: $clr-pink !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  font-family: $font-family-opensans !important;
 }
 a.theme-btn.btn-style-one.get-started:hover {
  color: $clr-white !important;
 }
 a.theme-btn.btn-style-one.get-started.mb-ilink {
  padding: 0.3125rem 1.25rem;
  font-size: 1rem !important;
 }
 img.mobile-logo {
  margin-top: 0.9375rem;
  width: 7.5rem !important;
 }
 .signin-mbmenu {
  margin-top: 1.0625rem !important;
  text-align: right;
 }
 .signin-mbmenu span {
  font-weight: $font-bold;
  font-size: 0.9375rem;
  margin-right: 0.8125rem;
 }
 .sec-title.mb-sec-title h2 {
  position: relative;
  color: $clr-white;
  font-weight: $font-semibold;
  line-height: 2rem;
  margin-top: -0.3125rem;
  font-family: $font-family-montserrat;
  font-size: 1.625rem !important;
  margin-left: 0.9375rem;
 }
 .sec-title.mb-sec-title .title {
  position: relative;
  color: $clr-white;
  font-size: 0.875rem;
  line-height: 2.6875rem;
  text-transform: uppercase;
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
  font-weight: $font-regular;
  font-family: $font-family-opensans;
  margin-left: 1.125rem;
 }
 p.desc.home.mb-home {
  padding-top: 0.625rem;
  font-size: 0.875rem;
  font-family: $font-family-opensans;
  font-weight: $font-regular;
  color: $clr-text-two;
  line-height: 1.25rem;
  margin-left: 0.9375rem;
  margin-bottom: 0rem;
 }
 /*footer menu*/
 ul.nav.flex-column.faq_content li a {
  color: $clr-white;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0.1875rem 1.25rem;
 }
 ul.get-in-touch-list.faq_content li a {
  color: $clr-white;
  font-size: 0.875rem;
  line-height: 1.5rem;
 }
 ul.get-in-touch-list.faq_content {
  color: $clr-white;
  font-size: 0.875rem;
 }
 ul.get-in-touch-list.faq_content p {
  color: $clr-white;
  font-size: 0.875rem;
  line-height: 1.5rem;
 }
 summary.faq_title {
  font-size: 0.9375rem;
  font-family: "Raleway", sans-serif;
  font-weight: $font-bold;
  color: $clr-white;
 }
 .faq_item {
  margin-top: 0.625rem;
 }
 span.follow-us {
  font-weight: $font-bold;
  font-size: 1rem;
  margin-right: 0.625rem;
  color: $clr-white;
 }
 .get-in-touch-list li span {
  font-size: 0.875rem;
  color: $clr-white;
  padding: 0.1875rem 0.1875rem 0.1875rem 1.25rem;
 }
 .main-footer .footer-widget {
  margin-left: 0.9375rem;
  margin-bottom: 1.25rem !important;
 }
 .mobile-logo-footer {
  margin-top: -0.9375rem;
  width: 8.125rem !important;
 }
 .list-link li {
  margin-bottom: 0rem !important;
  margin-left: 1.125rem;
 }
 ul.list-link {
  margin-top: 0.5rem;
 }
 a.icofont-ui-call {
  padding: 0.5rem !important;
 }
 a.icofont-envelope {
  padding: 0.5rem !important;
 }
 .sec-title.broker-title h2 {
  font-size: 1.625rem !important;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
 }
 .vline2 {
  height: 1.875rem !important;
  margin-top: 0.1875rem !important;
 }
 .broker-logos img {
  width: 8.125rem;
 }
 .broker-section {
  padding-bottom: 1.875rem !important;
 }
 .invest-title.mb-invest-title h2 {
  line-height: 2rem;
  margin: 1.875rem 0em 0;
  font-size: 1.625rem;
 }
 .invest-title.mb-invest-title p {
  line-height: 1.75rem;
  margin: 0.625rem 0em 2.5rem;
  font-size: 1.3125rem;
 }
 .publish-title.mb-publish-title h2 {
  line-height: 2rem;
  margin: 1.875rem 1.25rem 0;
  font-size: 1.625rem;
  text-align: center;
 }
 .publish-title.mb-publish-title p {
  margin: 0.875rem 0 0rem;
  text-align: center;
  line-height: 0.9375rem;
 }
 p.publish-btn {
  margin: 1.875rem 0 1.875rem !important;
 }
 p.btn-p.mbbtn-invest {
  margin: -1.25rem 0em 1.875rem;
 }
 .testimonial-block .inner-box.mb-home-advisor {
  width: 20.625rem;
  margin: 0 1.25rem;
  padding: 0.9375rem 0.625rem;
  height: 11.25rem;
 }
 .testimonial-block .inner-box.mb-portfolio {
  width: 20.625rem;
  margin: 0 1.25rem;
  padding: 0.9375rem 0.625rem;
  height: 11.25rem;
 }
 .vline {
  left: 30% !important;
  height: 9.375rem !important;
 }
 .portlogo-resize {
  width: 30% !important;
 }
 h2.home-top-invest.mb-hti {
  margin-top: 2.8125rem !important;
 }
 .sec-title.broker-title {
  margin-bottom: -0.625rem;
 }
 .sec-title.mb-sec-title {
  margin-bottom: -0.625rem;
 }
 .testimonial-block.mb-testimonial-block {
  margin-bottom: 1.25rem;
 }
 h2.port-heading {
  font-size: 1.125rem !important;
  line-height: 1.375rem !important;
 }
 .portfolio-section {
  padding-bottom: 1.25rem !important;
 }
 .invest-port {
  margin-bottom: -3.5em !important;
 }
 p.port-desc.home-port-desc {
  font-size: 0.875rem !important;
  -webkit-line-clamp: 4 !important;
 }
 .w-320 {
  height: 10rem !important;
  overflow: hidden !important;
 }
 .publish-port {
  border-right: 0.0625rem solid $clr-white !important;
 }
 h2.font1 {
  font-size: 0.875rem !important;
 }
 .mb-conven {
  padding: 0 0.9375rem 0 0.9375rem;
 }
 .icon-padd1.mb-icon-padd1 {
  padding-left: 1.125rem;
 }
 .icon-padd2.mb-icon-padd2 {
  padding-left: 0;
 }
 .icon-img.mb-icon-img {
  padding-top: 0.75rem;
 }
 img.icon-line.mb-icon-line {
  width: 0.25rem !important;
  height: 1.4375rem;
  margin-top: -0.75rem;
 }
 .con-div.mb-con-div {
  padding-top: 1.875rem;
 }
 .modal-main-front.popup-submit {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0rem !important;
  max-height: 100% !important;
 }
 .signup-container {
  padding: 0 7em !important;
 }
 svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.mb-icon.passwordicon-front {
  margin: 0rem 0rem 0rem 0rem !important;
 }
 svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.mb-icon.passwordicon-top-front.mb-passtf {
  margin: 0.5rem 0rem 0rem 0rem !important;
 }
 a.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineNone.fplink {
  margin: 0 0rem;
  font-size: 0.8125rem !important;
 }
 .btn {
  margin: 0.625rem 0.9375rem 0.625rem !important;
 }
 // img.mb-gwlogo {
 // 	width: 13.125rem;
 // }

 img.mb-dlogo.deseo-logo-popup {
  width: 18%;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.signup-logo {
  margin-top: 4.0625rem !important;
  margin-left: 0rem !important;
 }
 .cancel-mar {
  margin: 0.5rem 0.5rem 0rem 0 !important;
 }
 h2.sign-heading-front {
  font-size: 1.25rem !important;
  padding-top: 0.625rem !important;
 }
 h2.get-started-front {
  font-size: 1rem !important;
 }
 .account-text-front {
  font-size: 0.9375rem !important;
 }
 p.term-text-front {
  font-size: 0.9375rem !important;
  margin-left: 0 !important;
 }
 .term-policy-link {
  font-size: 0.9375rem !important;
 }
 button.btn.btn-1.hover-filled-slide-right.btn-mobile-front.mb-otp {
  line-height: 0.9375rem;
  margin: 1.5625rem 0rem 0 0 !important;
 }
 .mobile-ml {
  margin: 0 auto !important;
 }
 button.btn.btn-1.hover-filled-slide-right.margin-top {
  margin-top: 1.5625rem !important;
 }
 .mbverify-cont.mbverify-mobile {
  width: calc(100% + 0.9375rem) !important;
 }
 .otpinput-front input {
  margin: 0.3125rem 0.6875rem 0.3125rem !important;
  padding: 0.3125rem 0.3125rem 0.3125rem !important;
 }
 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardSuccess.MuiAlert-standard.mobile-msg {
  width: 100%;
 }
 button.btn.btn-1.hover-filled-slide-right.btn-mobile-front.mb-verify {
  line-height: 0.9375rem;
  margin: 0 0 0 0.3125rem !important;
 }
 button.cancel-btn.mv-back.mobile {
  margin: -1.25rem 0rem 0 !important;
 }
 .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.mb-ccode
  input.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall {
  padding: 0.53125rem 0.3125rem;
 }
 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardError.MuiAlert-standard.mobile-msg {
  margin: 0.9375rem 0rem 0rem 0.9375rem !important;
  width: auto !important;
 }
 svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.backicon {
  font-size: 1.3125rem;
 }
 .inner-box.portfolio.mb-portfolio {
  margin: 0rem 1.6875rem;
  padding: 0.9375rem 0rem 0;
 }
 .port-risk-img.mb-port-risk-img {
  margin-top: -0.5rem !important;
 }
 .portfolio-details h2.port-heading.mb-port-heading {
  font-size: 1.0625rem !important;
  line-height: 1.5rem !important;
  margin-top: 0.3125rem !important;
 }
 .portfolio-details.mb-portfolio-details {
  height: auto !important;
  padding: 0.3125rem 0.9375rem;
 }
 .head-border-port.portfolio-border.mb-portfolio-border {
  margin: -1rem 0 0.625rem !important;
 }
 .head-border-port.portfolio-border {
  margin: 0.625rem 0 0.625rem !important;
 }
 .inner-box.portfolio.mb-portfolio {
  min-height: 16.5625rem;
  overflow: scroll;
 }
 p.portfolio-heading {
  font-size: 0.875rem !important;
 }
 p.port-desc.advisor-name {
  font-size: 0.875rem !important;
  margin-left: 1.125rem !important;
  margin-top: -0.1875rem !important;
 }
 .portfolio-border-top p.port-desc {
  line-height: 1.25rem !important;
  margin-left: 0.625rem !important;
  font-size: 0.875rem !important;
 }
 .text-image.mb-text-image {
  left: 0.9375rem;
  font-size: 0.8125rem;
  top: 0.8125rem;
 }
 p.portfolio-date {
  margin-left: 0.9375rem !important;
 }
 .graph-icon {
  margin: 0.5rem 0rem 0 !important;
 }
 p.portheading.mb-portheading {
  margin-top: 6.8em;
  font-size: 1.5rem;
  padding: 0 0.625rem;
 }
 span.arrow.ti-angle-left {
  font-size: 1rem !important;
  margin-left: 0.625rem;
 }
 .btn-style-one {
  font-size: 0.875rem !important;
 }
 .btn-w160 {
  width: 7.8125rem !important;
 }
 main.dashboard-scroll.mb-dashboard-scroll.MuiBox-root {
  min-height: 50.9375rem;
  max-height: 50.9375rem;
 }
 .gradient-grid.desktop-hide p {
  width: 100%;
  position: absolute;
 }
 p.portfoliodetails-heading {
  font-size: 1.125rem !important;
  margin: 0rem 1.25rem 0 0.875rem !important;
 }
 .portnamegradauto.mb-portnamegradauto {
  margin-top: 10.5em !important;
 }
 /* .mobile-hide{display: none;}.desktop-hide{display: block !important;} */
 p.advisor-info.mb-advisor-info.advisor-info-details {
  font-size: 0.875rem;
 }
 .advisortop.mb-advisortop {
  margin-top: 10.5em !important;
 }
 p.advisorhead1.mb-advisorhead1 {
  font-size: 1rem;
  margin-left: 1.1875rem;
  line-height: 1.4375rem;
  margin-bottom: 0.625rem;
 }
 span.arrow.ti-angle-left.advisorarrow.mb-adarrow {
  margin-left: 0.875rem;
  font-size: 1rem !important;
 }
 .advisor-logo.mb-advisor-logo {
  border-radius: 1.5625rem;
  width: 5.9375rem;
  height: 5.3125rem;
 }
 a.advisor-social-icons.mb-advisor-social-icons {
  padding: 0.3125rem;
  font-size: 1rem;
  margin-right: 0.8125rem;
  margin-left: -0.3125rem;
 }
 h2.wip-text {
  font-size: 2.5rem !important;
  margin-left: 0.9375rem;
 }
 .MuiGrid-root.MuiGrid-item.wip-div2 {
  margin-top: 8em !important;
  margin-left: 0 !important;
 }
 .MuiGrid-root.MuiGrid-item.wip-div {
  margin-top: 2.9375rem !important;
  margin-left: -7em !important;
 }
 .advisor-logo-width.mb-advisor-logo-width {
  width: 8%;
  padding-left: 1.875rem;
 }
 .grid10-width.mb-grid10-width {
  width: 75%;
  margin-left: 6em !important;
  padding-left: 0.625rem;
 }
 .advisor-tab-width.mb-advisor-tab-width {
  width: 100%;
 }
 p.advisorhead3.mb-advisorhead3 {
  font-size: 1.375rem;
 }
 .advisor-social-icons {
  padding-bottom: 0.625rem;
 }
 p.advisor-info.mb-advisor-info {
  text-align: left !important;
  line-height: 0.875rem;
  font-size: 0.875rem;
 }
 .MuiGrid-root.MuiGrid-item.mobile-adtop {
  margin-top: -2.5rem;
  padding: 0 0.9375rem 0 10em;
 }
 .ipad-hide {
  display: none;
 }
 .text-image-details.mb-tid {
  font-size: 0.75rem;
  line-height: 2.6 !important;
  margin-left: 0.9375rem;
 }
 p.portdetails-date {
  margin-left: 0.5rem !important;
 }
 .border-bottom2 {
  border-bottom: 0.0625rem solid $clr-border-three !important;
 }
 p.nostock.mb-nostock {
  margin-left: 0rem !important;
 }
 p.port-desc.port-desc-details.portdetails-investstyle {
  margin-left: -0.625rem !important;
  word-break: break-word;
 }
 .modal-main-front.popup-submit.risk-profile {
  width: 100% !important;
 }
 .border-splan.mb-border-splan {
  width: 70%;
  margin: -0.5rem 9.6875rem !important;
 }
 .sub-plan.mb-sub-plan {
  padding: 0.5rem 0 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
 }
 .form-group-checkbox.mb-checkbox label:before {
  margin-top: -0.3125rem;
 }
 .form-group-checkbox.mb-checkbox input:checked + label:after {
  top: 0.0625rem;
 }
 .vline3 {
  height: 2.5rem;
 }
 .plan-price {
  margin: 0.625rem 0.3125rem 0rem 0 !important;
 }
 p.portfolio-heading.port-advisor.text-right {
  text-align: left !important;
 }
 p.port-desc.port-desc-details {
  font-size: 0.875rem !important;
 }
 .port-advisor-border {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.79);
 }
 p.rightarrow {
  margin: 0.6875rem 0rem 0rem 0.3125rem;
 }
 p.port-advisor {
  line-height: 1.25rem !important;
 }
 .pleft-20 {
  padding-left: 0 !important;
  margin-bottom: 0.3125rem !important;
 }
 p.port-desc1 {
  font-size: 0.875rem !important;
  line-height: 1.375rem !important;
 }
 p.port-desc.port-desc-details.port-advisor-border.lineheight.mb-alh {
  line-height: 1.375rem !important;
 }
 .MuiGrid-root.MuiGrid-item.d-flex-portinfo.mt35 {
  margin-top: 1.5625rem !important;
 }
 .mbgraph-display {
  padding: 0 0.9375rem;
 }
 .border-right {
  border-right: 0.125rem solid #505050 !important;
 }
 h4.portdetails-question {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
 }
 p.portdetails-answer {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  margin-bottom: 1.25rem !important;
 }
 p.portdetails-answer p {
  line-height: 1.25rem;
 }
 .pl-40 {
  padding-left: 0.9375rem !important;
 }
 .graph-img {
  padding-left: 2.1875rem !important;
 }
 .MuiGrid-root.bg-gray.port-graph-bottom.mb-pgb {
  padding-bottom: 0 !important;
 }
 h2.risk-profile-ques {
  font-size: 1.25rem !important;
 }
 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardError.MuiAlert-standard.mbdisplay-error {
  margin: 0.9375rem 0rem 0rem 0.5rem !important;
  width: 100% !important;
 }
 button.cancel-btn.mv-back.subplanarrow {
  margin: -1.375rem 0rem !important;
 }
 .actual-price {
  margin-top: 0.9375rem;
 }
 .account-bal {
  text-align: left;
 }
 .portfolio-sec-details.mb-psd {
  padding-bottom: 0 !important;
 }
 .modal-front {
  background: rgb(255, 255, 255);
 }
 input.search-portfolio.mb-search-portfolio {
  margin-top: 0.9375rem;
 }
 input.search-portfolio.mb-port-search {
  margin-left: -14rem;
  margin-top: 4rem;
 }
 button.search-icon.mb-search-icon {
  left: 63%;
 }
 .home-about-slider .carousel .control-dots {
  bottom: 3.75rem !important;
 }
 p.term-desc {
  padding: 0.9375rem 0.9375rem 0 !important;
  font-size: 0.9375rem !important;
 }
 .sec-title.term h2 {
  margin-top: 0.9375rem !important;
 }
 ol.ol-type-number {
  margin-left: 3.875rem !important;
 }
 ol.ol-type-number > li:before {
  border-radius: 0.75rem;
 }
 .term ol.ol-type-small {
  padding: 0 0.9375rem 0 0rem !important;
  font-size: 0.9375rem !important;
  margin-left: 1.625rem !important;
 }
 ol.ol-type-number {
  font-size: 1.0625rem !important;
 }
 .term ol.ol-type-roman {
  font-size: 0.9375rem !important;
  margin-left: 2.125rem !important;
 }
 ol.ol-type-number1 {
  margin-left: 1.6875rem !important;
  font-size: 0.9375rem !important;
  margin-top: 0.75em !important;
 }
 .container-loader.mb-loader {
  top: 46%;
  left: 49%;
 }
 .ipad-portwidth {
  width: 45%;
  margin-right: 1.25rem !important;
 }
 .portfolio-section.loader.mb-loader,
 .portfolio-section.loader-popup.mb-loader {
  height: 142vw;
  left: 0;
  position: fixed;
  top: 0%;
  transition: opacity 0.3s;
  width: 100vw;
  z-index: 99999999;
 }
 .ipad-wip {
  margin: 6.5625rem 0;
 }
 .distributor-port {
  padding-left: 0.21875rem;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.advisorsearch {
  margin-left: -0.625rem;
  margin-bottom: -0.9375rem;
 }
 .sec-title.mbpublisher {
  margin-top: 9.5em;
 }
 .sec-title.mb-sec-title.mbpublisher h2 {
  line-height: 1.25rem !important;
  font-size: 1.125rem !important;
  color: $clr-hyperlink;
  font-weight: $font-semibold !important;
 }
 .sec-title .title.mb-publisher {
  line-height: 1.25rem;
  text-transform: none;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
 }
 h2.portfolio-steps.mb-portfolio-steps {
  font-size: 1.125rem;
  line-height: 1.25rem;
  margin: 1.5625rem 0 1.875rem;
  color: $clr-hyperlink;
  font-weight: $font-bold;
 }
 h4.stephead {
  font-size: 0.8125rem !important;
  line-height: 1.125rem !important;
 }
 p.steptext {
  line-height: 1.125rem !important;
  font-size: 0.8125rem !important;
  margin-top: 1.25rem !important;
 }
 h2.invest-app.mbhtb {
  line-height: 1.25rem !important;
  font-size: 1.125rem !important;
  margin-top: 1.5625rem;
  color: $clr-hyperlink;
  font-weight: $font-bold !important;
 }
 .tab-indicator {
  margin-top: 20px;
 }
 button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.tab-head {
  width: 36% !important;
  font-size: 0.8125rem !important;
  margin-top: 1.25rem;
 }
 p.invest-text {
  font-size: 0.875rem !important;
  margin: 1.25rem;
  line-height: 1.25rem !important;
 }
 .mb-inblock.mbib {
  display: inline-block;
 }
 p.tab-text.mb-tab-text {
  line-height: 1.25rem;
  font-size: 0.8125rem;
  margin-top: 0rem;
 }
 .tabimg1 {
  text-align: center !important;
 }
 .tabimg2 {
  text-align: center !important;
 }
 .tabimg3 {
  text-align: center !important;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.text-right.tabimg1.css-1fyyp8j-MuiGrid-root img {
  width: 45%;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.text-right.tabimg2.css-1fyyp8j-MuiGrid-root img {
  width: 45%;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.text-right.tabimg3.css-1fyyp8j-MuiGrid-root img {
  width: 45%;
 }
 .mb-gsbtn {
  margin-top: 0.625rem !important;
 }
 .step-border {
  height: 11.875rem;
 }
 .content-column.mb-0.investapp-mar {
  margin: 0 2em 0 0 !important;
 }
 p.note {
  margin: 1.5625rem 0 -0.9375rem;
 }
 .ipad-ivesttable {
  padding: 0 1em;
 }
 .ipad-ivesttable-head {
  padding: 0 3.5em;
 }
 p.account-value.sell {
  left: 0.625rem;
 }
 p.account-value.buy {
  left: 0.625rem;
 }
 .sell-height {
  padding: 0 0em !important;
 }
 .sell-mt {
  padding: 0 2.5em !important;
 }
 .buy-mt {
  padding: 0 2.5em !important;
 }
 p.account-bal.mb-acbal {
  margin-left: 0rem;
 }
 .sell-container {
  padding: 0 0em !important;
 }
 .sell-containerhead {
  padding: 0 2em !important;
 }
 .inner-box.portfolio.dashboard-port.mb-dashboard-port {
  width: 18.75rem;
  margin: 0rem 0.625rem 0rem;
  min-height: 28.875rem;
 }
}

/* Device = Tablets, Ipads (landscape) */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
/* @media screen and (min-width: 800px) {
	#menu {margin: -6.25rem 0 0 -46em;}
	.publish-title h2{margin: 1.875rem 0.3125rem 0;font-size: 1.5625rem;}
	.con-div.mb-con-div {padding: 1.875rem 0.9375rem 0;}
	.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.advisorsearch{margin-left: -1.875rem;}
} */

/* Device = Low Resolution Tablets, Mobiles (Landscape) */
@media (min-width: 481px) and (max-width: 767px) {
}

/* Device = Most of the Smartphones Mobiles (Portrait) */
@media (min-width: 360px) and (max-width: 640px) {
 .invest-container {
  margin-bottom: 0em !important;
 }
 .footer-widget.links-widget p {
  line-height: 2;
  font-size: 14px;
 }
 .foot-logo {
  margin-left: 15px;
 }
 .main-footer {
  padding-top: 1em !important;
 }
 .footer-details .col-lg-4.l-float {
  width: 100% !important;
 }
  .loader-container.mobile-view-loading .loader.dash-loading {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 90px;
 }
 .loader-container.mobile-view-loading {
  position: relative;
 }
 .mobile-view-loading {
  display: block !important;
 }
 .desktop-view-loading {
  display: none !important;
 }
 button.theme-btn.btn-style-one.get-started.btn-w160.btn-dash {
  min-width: 100px !important;
  font-size: 14px !important;
  line-height: 1.2;
 }
 .invest-container.mb-investcont {
  display: flex;
 }
 .vline3 {
  height: 2.5rem !important;
 }
 p.congr-text {
  font-size: 1rem !important;
 }
 .mb-gwlogo {
  width: 65% !important;
 }
 p.port-desc.dashboard.line-break {
  margin-left: 0.3125rem !important;
 }
 .mobilemenu {
  display: block !important;
 }
 .desktopmenu {
  display: none;
 }
 .mobile-footer {
  display: block !important;
 }
 .desktop-footer {
  display: none;
 }
 li a.theme-btn.btn-style-one.get-started {
  width: 18.125rem !important;
  color: $clr-white !important;
  font-weight: 700 !important;
  font-size: 1.125rem !important;
  font-family: $font-family-opensans !important;
 }
 a.theme-btn.btn-style-one.get-started:hover {
  color: $clr-white !important;
 }
 a.theme-btn.btn-style-one.get-started.mb-ilink {
  padding: 0.3125rem 1.25rem;
  font-size: 1rem !important;
 }
 img.mobile-logo {
  margin-top: 0.9375rem;
  // width: 7.5rem !important;
  width: 60%;
 }
 .signin-mbmenu {
  margin-top: 1.25rem !important;
  text-align: right;
 }
 .signin-mbmenu span {
  font-weight: $font-bold;
  font-size: 0.9375rem;
  margin-right: 0.8125rem;
 }
 .sec-title.mb-sec-title h2 {
  position: relative;
  font-weight: $font-semibold;
  line-height: 2rem;
  margin-top: -0.3125rem;
  font-family: $font-family-montserrat;
  font-size: 1.75rem;
  margin-left: 0.9375rem;
 }
 .sec-title.mb-sec-title .title {
  position: relative;
  font-size: 0.875rem;
  line-height: 2.6875rem;
  text-transform: uppercase;
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
  font-weight: $font-regular;
  font-family: $font-family-opensans;
  margin-left: 1.125rem;
 }
 p.desc.home.mb-home {
  padding-top: 0.625rem;
  font-size: 0.875rem;
  font-family: $font-family-opensans;
  font-weight: $font-regular;
  color: $clr-text-two;
  line-height: 1.25rem;
  margin-left: 0.9375rem;
  margin-bottom: 0rem;
 }
 /*footer menu*/
 ul.nav.flex-column.faq_content li a {
  color: $clr-white;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0.1875rem 1.25rem;
 }
 ul.get-in-touch-list.faq_content li a {
  color: $clr-white;
  font-size: 0.875rem;
  line-height: 1.5rem;
 }
 ul.get-in-touch-list.faq_content {
  color: $clr-white;
  font-size: 0.875rem;
 }
 ul.get-in-touch-list.faq_content p {
  color: $clr-white;
  font-size: 0.875rem;
  line-height: 1.5rem;
 }
 summary.faq_title {
  font-size: 0.9375rem;
  font-family: "Raleway", sans-serif;
  font-weight: $font-bold;
  color: $clr-white;
 }
 .faq_item {
  margin-top: 0.625rem;
 }
 span.follow-us {
  font-weight: $font-bold;
  font-size: 1rem;
  margin-right: 0.625rem;
  color: $clr-white;
 }
 .get-in-touch-list li span {
  font-size: 0.875rem;
  color: $clr-white;
  padding: 0.1875rem 0.1875rem 0.1875rem 1.25rem;
 }
 .main-footer .footer-widget {
  margin-left: 0.9375rem;
  margin-bottom: 1.25rem !important;
 }
 .mobile-logo-footer {
  margin-top: -0.9375rem;
  width: 8.125rem !important;
 }
 .list-link li {
  margin-bottom: 0rem !important;
  margin-left: 1.125rem;
 }
 ul.list-link {
  margin-top: 0.5rem;
 }
 a.icofont-ui-call {
  padding: 0.5rem !important;
 }
 a.icofont-envelope {
  padding: 0.5rem !important;
 }
 .sec-title.broker-title h2 {
  font-size: 1.75rem !important;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
 }
 .vline2 {
  height: 1.875rem !important;
  margin-top: 0.1875rem !important;
 }
 .broker-logos img {
  width: 8.125rem;
 }
 .broker-section {
  padding-bottom: 1.875rem !important;
 }
 .invest-title.mb-invest-title h2 {
  line-height: 2rem;
  margin: 1.875rem 0em 0;
  font-size: 1.75rem;
 }
 .invest-title.mb-invest-title p {
  line-height: 1.75rem;
  margin: 0.625rem 0em 2.5rem;
  font-size: 1.3125rem;
 }
 .publish-title.mb-publish-title h2 {
  line-height: 2rem;
  margin: 1.875rem 0.9375rem 0;
  font-size: 1.75rem;
  text-align: center;
 }
 .publish-title.mb-publish-title p {
  margin: 0.875rem 0 0rem;
  text-align: center;
  line-height: 1.1;
  padding: 10px;
  font-size: 1.2rem;
 }
 p.publish-btn {
  margin: 1.875rem 0 1.875rem !important;
 }
 p.btn-p.mbbtn-invest {
  margin: -1.25rem 0em 1.875rem;
 }
 .testimonial-block .inner-box.portfolio.mb-portfolio {
  width: 20.625rem;
  margin: 0 0;
  padding: 0.9375rem 0.625rem;
  height: 11.25rem;
 }
 .vline {
  left: 30% !important;
  height: 9.375rem !important;
 }
 .portlogo-resize {
  width: 30% !important;
 }
 h2.home-top-invest.mb-hti {
  margin-top: 2.8125rem !important;
 }
 .sec-title.broker-title {
  margin-bottom: -0.625rem;
 }
 .sec-title.mb-sec-title {
  margin-bottom: -0.625rem;
 }
 .testimonial-block.mb-testimonial-block {
  margin-bottom: 1.25rem;
 }
 h2.port-heading {
  font-size: 1.125rem !important;
  line-height: 1.375rem !important;
 }
 .portfolio-section {
  padding-bottom: 1.25rem !important;
 }
 .invest-port {
  margin-bottom: -3.5em !important;
 }
 p.port-desc.home-port-desc {
  font-size: 0.875rem !important;
  -webkit-line-clamp: 4 !important;
 }
 .w-320 {
  height: 10rem !important;
  overflow: hidden !important;
 }
 .publish-port {
  border-right: 0rem solid $clr-white !important;
  width: 100% !important;
 }
 .distributor-port {
  width: 100% !important;
 }
 h2.font1 {
  font-size: 0.875rem !important;
 }
 .mb-conven {
  padding: 0 0.9375rem 0 0.9375rem;
 }
 .icon-padd1.mb-icon-padd1 {
  padding-left: 1.125rem;
 }
 .icon-padd2.mb-icon-padd2 {
  padding-left: 0;
 }
 .icon-img.mb-icon-img {
  padding-top: 0.75rem;
 }
 img.icon-line.mb-icon-line {
  width: 0.25rem !important;
  height: 1.4375rem;
  margin-top: -0.75rem;
 }
 .con-div.mb-con-div {
  padding-top: 1.875rem;
 }
 .modal-main-front.popup-submit {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0rem !important;
  max-height: 100% !important;
 }
 .signup-container {
  padding: 0 1.25rem !important;
 }
 svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.mb-icon.passwordicon-front {
  margin: 0rem 0rem 0rem 0rem !important;
 }
 svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.mb-icon.passwordicon-top-front.mb-passtf {
  margin: 0.625rem 0rem 0rem 0rem !important;
 }
 a.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineNone.fplink {
  margin: 0 -1.375rem;
  font-size: 0.8125rem !important;
 }
 .btn {
  margin: 0.625rem 0.9375rem 0.625rem !important;
 }
 // img.mb-gwlogo {
 // 	width: 9.375rem;
 // }

 img.mb-dlogo.deseo-logo-popup {
  width: 27%;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.signup-logo {
  margin-top: 4.0625rem !important;
  margin-left: 0rem !important;
 }
 .cancel-mar {
  margin: 0.5rem 0.5rem 0rem 0 !important;
 }
 h2.sign-heading-front {
  font-size: 1.25rem !important;
  padding-top: 0.625rem !important;
 }
 h2.get-started-front {
  font-size: 1rem !important;
 }
 .account-text-front {
  font-size: 0.9375rem !important;
 }
 p.term-text-front {
  font-size: 0.9375rem !important;
  margin-left: 0 !important;
 }
 .term-policy-link {
  font-size: 0.9375rem !important;
 }
 button.btn.btn-1.hover-filled-slide-right.btn-mobile-front.mb-otp {
  line-height: 0.9375rem;
  margin: 1.5625rem 0rem 0 0 !important;
 }
 .mobile-ml {
  margin: 0 auto !important;
 }
 button.btn.btn-1.hover-filled-slide-right.margin-top {
  margin-top: 1.5625rem !important;
 }
 .mbverify-cont.mbverify-mobile {
  width: calc(100% + 0.9375rem) !important;
 }
 .otpinput-front input {
  margin: 0.3125rem 0.1875rem 0.3125rem !important;
  padding: 0.3125rem 0.3125rem 0.3125rem !important;
 }
 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardSuccess.MuiAlert-standard.mobile-msg {
  width: 89%;
 }
 button.btn.btn-1.hover-filled-slide-right.btn-mobile-front.mb-verify {
  line-height: 0.9375rem;
  margin: 0 0 0 0.3125rem !important;
 }
 button.cancel-btn.mv-back.mobile {
  margin: -1.25rem 0rem 0 !important;
 }
 .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.mb-ccode
  input.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall {
  padding: 0.53125rem 0.3125rem;
 }
 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardError.MuiAlert-standard.mobile-msg {
  margin: 0.9375rem 0rem 0rem 0.9375rem !important;
  width: auto !important;
 }
 svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.backicon {
  font-size: 1.3125rem;
 }
 .MuiGrid-root.inner-box.portfolio.mb-portfolio {
  margin: 0rem 0.9375rem;
  padding: 0.9375rem 0rem 0;
 }
 .port-risk-img.mb-port-risk-img {
  margin-top: -0.5rem !important;
 }
 .portfolio-details h2.port-heading.mb-port-heading {
  font-size: 1.0625rem !important;
  line-height: 1.5rem !important;
  margin-top: 0.3125rem !important;
 }
 .portfolio-details.mb-portfolio-details {
  height: auto !important;
  padding: 0.3125rem 0.9375rem;
 }
 .portfolio-border {
  margin: 0.3125rem 0 0.625rem !important;
 }
 .MuiGrid-root.inner-box.portfolio.mb-portfolio {
  min-height: 16.5625rem;
  overflow: scroll;
 }
 p.portfolio-heading {
  font-size: 0.875rem !important;
 }
 p.port-desc.advisor-name {
  font-size: 0.875rem !important;
  margin-left: 1.125rem !important;
 }
 .portfolio-border-top p.port-desc {
  line-height: 1.25rem !important;
  margin-left: 0.625rem !important;
  font-size: 0.875rem !important;
 }
 .text-image.mb-text-image {
  left: 0.9375rem;
  font-size: 0.8125rem;
  top: 0.8125rem;
 }
 p.portfolio-date {
  margin-left: 0.9375rem !important;
 }
 .graph-icon {
  margin: 0.5rem 0rem 0 !important;
 }
 p.portheading.mb-portheading {
  margin-top: 6.8em;
  font-size: 1.5rem;
  padding: 0 0.625rem;
 }
 span.arrow.ti-angle-left {
  font-size: 1rem !important;
  margin-left: 0.3125rem;
 }
 .btn-style-one {
  font-size: 1rem !important;
 }

 .status-btn-container {
  margin-top: 0.625rem !important;
  padding-bottom: 12px;
 }

 .portdetail-gradients.mb-portdetail-gradients {
  padding: 1.5625rem 0.9375rem 1.5625rem 0.9375rem;
  margin: 1.25rem 0rem 0 0rem !important;
 }
 .gradient-grid.desktop-hide p {
  width: 100%;
  position: absolute;
 }
 p.portfoliodetails-heading {
  font-size: 1.125rem !important;
  margin: 0rem 1.25rem 0 0.875rem !important;
 }
 .portnamegradauto.mb-portnamegradauto {
  margin-top: 10.5em !important;
 }
 .mobile-hide {
  display: none;
 }
 .mobile-hide.mb-dview {
  display: none;
 }
 .desktop-hide {
  display: block !important;
 }
 p.advisor-info.mb-advisor-info.advisor-info-details {
  font-size: 0.875rem;
 }
 .advisortop.mb-advisortop {
  margin-top: 10.5em !important;
 }
 p.advisorhead1.mb-advisorhead1 {
  font-size: 1rem;
  margin-left: 0.875rem;
  line-height: 1.4375rem;
  margin-bottom: 0.625rem;
 }
 span.arrow.ti-angle-left.advisorarrow.mb-adarrow {
  margin-left: 0.625rem;
  font-size: 1rem !important;
 }
 .advisor-logo.mb-advisor-logo {
  border-radius: 1.5625rem;
  width: 5.9375rem;
  height: 5.3125rem;
 }
 a.advisor-social-icons.mb-advisor-social-icons {
  padding: 0.3125rem;
  font-size: 1rem;
  margin: 0 0.8125rem 0.25rem -0.3125rem !important;
  display: inline-block;
 }
 h2.wip-text {
  font-size: 1.5rem !important;
  margin-left: 0.9375rem;
 }
 .MuiGrid-root.MuiGrid-item.wip-div2 {
  margin-top: 8em !important;
  margin-left: 0 !important;
 }
 .MuiGrid-root.MuiGrid-item.wip-div {
  margin-top: 2.9375rem !important;
  margin-left: -7em !important;
 }
 .mobile-w100 {
  width: 100%;
  text-align: left !important;
 }
 .advisor-logo-width.mb-advisor-logo-width {
  width: 8%;
  padding-left: 0.9375rem;
 }
 .grid10-width.mb-grid10-width {
  width: 61%;
  margin-left: 6em !important;
  padding-left: 0.625rem;
 }
 .advisor-tab-width.mb-advisor-tab-width {
  width: 100%;
 }
 p.advisorhead3.mb-advisorhead3 {
  font-size: 1.375rem;
  line-height: 1.375rem;
 }
 .advisor-social-icons {
  padding-bottom: 0.625rem;
 }
 p.advisor-info.mb-advisor-info {
  text-align: left !important;
  line-height: 0.875rem;
  font-size: 0.875rem;
 }
 .MuiGrid-root.MuiGrid-item.mobile-adtop {
  margin-top: 1.25rem;
  padding: 0 0.9375rem;
 }
 .text-image-details.mb-tid {
  font-size: 0.75rem;
  line-height: 2.6 !important;
  margin-left: 0.9375rem;
 }
 p.portdetails-date.mb-date {
  margin-left: 0.4rem !important;
  margin-top: -4px !important;
 }
 .border-bottom2.mb-border-bottom2 {
  border-bottom: 0rem solid $clr-border-three !important;
 }
 p.nostock.mb-nostock {
  margin-left: 0rem !important;
 }
 p.port-desc.port-desc-details.portdetails-investstyle {
  margin-left: -0.625rem !important;
  word-break: break-word;
 }
 .modal-main-front.popup-submit.risk-profile {
  width: 100% !important;
 }
 .border-splan.mb-border-splan {
  width: 90%;
 }
 .sub-plan.mb-sub-plan {
  padding: 0.5rem 0 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
 }
 .form-group-checkbox.mb-checkbox label:before {
  margin-top: 1.125rem;
 }
 .form-group-checkbox.mb-checkbox input:checked + label:after {
  top: 0.8125rem;
 }
 .plan-price {
  margin: 0.625rem 0.3125rem 0rem 0 !important;
 }
 p.rightarrow {
  display: none;
 }
 p.portfolio-heading.port-advisor.text-right {
  text-align: left !important;
 }
 p.port-desc.port-desc-details {
  font-size: 0.875rem !important;
 }
 .port-advisor-border {
  border-bottom: 0rem solid rgba(255, 255, 255, 0.79) !important;
 }
 .modisplay-border {
  border-top: 0.0625rem solid $clr-border-three;
  padding: 0.5rem 0rem 0.25rem;
  margin-bottom: 0.125rem !important;
 }
 p.port-advisor {
  line-height: 1.25rem !important;
 }
 .pleft-20 {
  padding-left: 0 !important;
  margin-bottom: -1.25rem !important;
 }
 p.port-desc1 {
  font-size: 0.875rem !important;
  line-height: 1.375rem !important;
 }
 p.port-desc.port-desc-details.port-advisor-border.lineheight.mb-alh {
  line-height: 1.375rem !important;
 }
 .MuiGrid-root.MuiGrid-item.d-flex-portinfo.mt35 {
  margin-top: 1.5625rem !important;
 }
 .mbgraph-display {
  display: inline-block !important;
  padding: 0 0.9375rem;
 }
 .border-right {
  border-right: 0rem solid #505050 !important;
 }
 h4.portdetails-question {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
 }
 p.portdetails-answer {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  margin-bottom: 1.25rem !important;
 }
 p.portdetails-answer p {
  line-height: 1.25rem;
 }
 .pl-40 {
  padding-left: 0rem !important;
 }
 .graph-img {
  padding-left: 0.9375rem !important;
 }
 .MuiGrid-root.bg-gray.port-graph-bottom.mb-pgb {
  padding-bottom: 0 !important;
 }
 h2.risk-profile-ques {
  font-size: 1.25rem !important;
 }
 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardError.MuiAlert-standard.mbdisplay-error {
  margin: 0.9375rem 0rem 0rem 0.5rem !important;
  width: 100% !important;
 }
 button.cancel-btn.mv-back.subplanarrow {
  margin: -1.375rem 0rem !important;
 }
 .actual-price {
  margin-top: 0.9375rem;
 }
 .account-bal {
  text-align: left;
 }
 .portfolio-sec-details.mb-psd {
  padding-bottom: 0 !important;
 }
 .modal-front {
  background: rgb(255, 255, 255);
 }
 .portfolio-section.loader.mb-loader,
 .portfolio-section.loader-popup.mb-loader {
  top: 10%;
  left: 0%;
  width: 100%;
  height: 100%;
 }
 .container-loader.mb-loader {
  top: 46%;
  left: 48%;
 }
 h2.question-risk {
  font-size: 1rem;
  margin: 1.25rem 1.25rem 0.9375rem;
 }
 .MuiFormGroup-root.text-left.css-dmmspl-MuiFormGroup-root {
  margin: 0rem 1.25rem;
 }
 .ipad-wip {
  margin: 3.75rem 0 9.375rem;
 }
 .advisorarrow.mb-adarrow {
  width: 1.4375rem;
 }
 .portdetailsarrow {
  width: 1.5625rem !important;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.advisorsearch {
  margin-left: -1.875rem !important;
  margin-bottom: -1.25rem;
  margin-top: 1.25rem;
 }
 .sec-title.mbpublisher {
  margin-top: 9.5em;
 }
 .sec-title.mb-sec-title.mbpublisher h2 {
  line-height: 1.25rem !important;
  font-size: 1.125rem !important;
  color: $clr-hyperlink;
  font-weight: $font-semibold !important;
 }
 .sec-title .title.mb-publisher {
  line-height: 1.25rem;
  text-transform: none;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
 }
 h2.portfolio-steps.mb-portfolio-steps {
  font-size: 1.125rem;
  line-height: 1.25rem;
  margin: 1.5625rem 0 1.875rem;
  color: $clr-hyperlink;
  font-weight: $font-bold;
 }
 h4.stephead {
  font-size: 0.8125rem !important;
  line-height: 1.125rem !important;
 }
 p.steptext {
  line-height: 1.125rem !important;
  font-size: 0.8125rem !important;
  margin-top: 1.25rem !important;
 }
 h2.invest-app.mbhtb {
  line-height: 1.25rem !important;
  font-size: 1.125rem !important;
  margin-top: 1.5625rem;
  color: $clr-hyperlink;
  font-weight: $font-bold !important;
 }
 .tab-indicator {
  margin-top: 20px;
 }
 button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.tab-head {
  width: 36% !important;
  font-size: 0.8125rem !important;
  margin-top: 1.25rem;
 }
 p.invest-text {
  font-size: 0.875rem !important;
  margin: 1.25rem;
  line-height: 1.25rem !important;
 }
 .mb-inblock.mbib {
  display: inline-block;
 }
 p.tab-text.mb-tab-text {
  line-height: 1.25rem;
  font-size: 0.8125rem;
  margin-top: 0rem;
 }
 .tabimg1 {
  text-align: center !important;
 }
 .tabimg2 {
  text-align: center !important;
 }
 .tabimg3 {
  text-align: center !important;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.text-right.tabimg1.css-1fyyp8j-MuiGrid-root img {
  width: 70%;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.text-right.tabimg2.css-1fyyp8j-MuiGrid-root img {
  width: 70%;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.text-right.tabimg3.css-1fyyp8j-MuiGrid-root img {
  width: 70%;
 }
 .mb-gsbtn {
  margin-top: 0.625rem !important;
 }
 label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.question-label {
  font-size: 0.875rem !important;
  margin-left: -0.625rem !important;
 }
 p.note {
  margin: 1.5625rem 0 -0.9375rem;
 }
 button.account-value {
  padding: 0.1875rem 0 0.1875rem 0.5625rem;
  font-size: 0.875rem;
 }
 .cross-font {
  font-size: 0.625rem;
 }
 p.invest-head {
  font-size: 0.8125rem !important;
 }
 p.invest-subhead {
  padding: 0 0.625rem 0 0 !important;
  font-size: 0.8125rem !important;
 }
 p.invest-subhead.dash-order {
  width: 70px;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4.MuiGrid-grid-md-4.l-float.sell-ml p.account-bal {
  text-align: center;
 }
 .dm-mobile-hide {
  display: none;
 }
 .dm-desktop-hide {
  display: block !important;
 }
 .portfolio-border-top p.port-desc.dashboard.mb-dashboard {
  margin-left: 0.3125rem;
 }
 .order-subhead {
  font-size: 0.875rem !important;
 }
 .inner-box.portfolio.orderbook.mb-orderbook {
  /* margin: 0rem 0rem 0rem;
		padding: 0.9375rem 1.25rem 0.9375rem; */
  margin: 0rem -0.625rem 0rem 0.625rem;
  padding: 0rem 0rem 0rem;
 }
 .inner-box.portfolio.orderbook.mb-orderbook.mb-risk {
  margin: 0rem 0rem 0rem;
  padding: 0.9375rem 1.25rem 0.9375rem;
 }
 p.portfolio-heading.port-advisor.mobile-obs {
  border-bottom: 0.1rem solid #969696 !important;
  margin: 0.3125rem 0 0;
  padding: 0.9375rem 0.85rem 1.25rem !important;
 }
 p.portfolio-heading.port-advisor.mobile-obs {
  color: #ffffff !important;
 }
 .inner-box.portfolio.orderbook-horizontal-scroll {
  width: fit-content !important;
 }
 p.portfolio-date.dashboard.amountinvest {
  padding-left: 1rem !important;
 }
 .one-line {
  width: 100%;
  display: inline-block; /* important */
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 0.1rem solid #707070;

  /* when not hovering show ellipsis */
  &:not(:hover) {
   text-overflow: ellipsis;
  }

  /* animate on either hover or focus */
  &:hover,
  &:focus {
   p.border-pn {
    display: inline-block;
    animation-name: scroll-text;
    animation-duration: 7s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    /* FYI this would be the shorthand:
			  animation: scroll-text 5s ease 0s 2 normal;
			*/
   }
  }

  /* define the animation */
  @keyframes scroll-text {
   0% {
    transform: translateX(0%);
   }
   90% {
    transform: translateX(-100%);
   }
   95% {
    transform: translateX(0%);
   }
   100% {
    transform: translateX(0%);
   }
  }
 }
 p.border-pn {
  border-bottom: 0rem solid #707070 !important;
 }
}

@media (min-width: 320px) and (max-width: 480px) {
 .invest-container {
  margin-bottom: 0em !important;
 }
 .footer-widget.links-widget p {
  line-height: 2;
  font-size: 14px;
 }
 .foot-logo {
  margin-left: 15px;
 }
 .main-footer {
  padding-top: 1em !important;
 }
 .footer-details .col-lg-4.l-float {
  width: 100% !important;
 }
 .one-line {
  width: 100%;
  display: inline-block; /* important */
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 0.1rem solid #707070;

  /* when not hovering show ellipsis */
  &:not(:hover) {
   text-overflow: ellipsis;
  }

  /* animate on either hover or focus */
  &:hover,
  &:focus {
   p.border-pn {
    display: inline-block;
    animation-name: scroll-text;
    animation-duration: 7s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    /* FYI this would be the shorthand:
			  animation: scroll-text 5s ease 0s 2 normal;
			*/
   }
  }

  /* define the animation */
  @keyframes scroll-text {
   0% {
    transform: translateX(0%);
   }
   90% {
    transform: translateX(-100%);
   }
   95% {
    transform: translateX(0%);
   }
   100% {
    transform: translateX(0%);
   }
  }
 }
 p.border-pn {
  border-bottom: 0rem solid #707070 !important;
 }
 .inner-box.portfolio.orderbook-horizontal-scroll {
  width: fit-content !important;
  padding: 0 !important;
 }
 .MuiGrid-root.inner-box.portfolio.orderbook.orderbook-horizontal-scroll.mb-orderbook.dash-order-width {
  padding: 15px !important;
  height: 450px;
  overflow: scroll;
  width: 95% !important;
 }
 .dash-btn-center {
  left: 50% !important;
 }
 p.portfolio-date.dashboard.amountinvest {
  padding-left: 1rem !important;
 }
 p.portfolio-heading.port-advisor.mobile-obs {
  border-bottom: 0.1rem solid #969696 !important;
  margin: 0.3125rem 0 0;
  padding: 0.9375rem 0.85rem 1.25rem !important;
 }
 p.portfolio-heading.port-advisor.mobile-obs {
  color: #ffffff !important;
 }
 .vline3 {
  height: 2.5rem !important;
 }
 p.congr-text {
  font-size: 1rem !important;
 }
 .mb-gwlogo {
  width: 65% !important;
 }
 p.port-desc.dashboard.line-break {
  margin-left: 0.3125rem !important;
 }
 .mobilemenu {
  display: block !important;
 }
 .desktopmenu {
  display: none;
 }
 .mobile-footer {
  display: block !important;
 }
 .desktop-footer {
  display: none;
 }
 li a.theme-btn.btn-style-one.get-started {
  width: 18.125rem !important;
  color: $clr-white !important;
  font-weight: 700 !important;
  font-size: 1.125rem !important;
  font-family: $font-family-opensans !important;
 }
 a.theme-btn.btn-style-one.get-started:hover {
  color: $clr-white !important;
 }
 a.theme-btn.btn-style-one.get-started.mb-ilink {
  padding: 0.3125rem 1.25rem;
  font-size: 1rem !important;
 }
 img.mobile-logo {
  margin-top: 0.9375rem;
  // width: 7.5rem !important;
  width: 60%;
 }
 .signin-mbmenu {
  margin-top: 1.0625rem !important;
  text-align: right;
 }
 .signin-mbmenu span {
  font-weight: $font-bold;
  font-size: 0.9375rem;
  margin-right: 0.8125rem;
  color: $clr-bg-gray;
 }
 .sec-title.mb-sec-title h2 {
  position: relative;
  font-weight: $font-semibold !important;
  line-height: 2rem !important;
  margin-top: -0.3125rem;
  font-family: $font-family-montserrat;
  font-size: 1.75rem !important;
  margin-left: 0.9375rem;
 }
 .sec-title.mb-sec-title .title {
  position: relative;
  font-size: 0.875rem;
  line-height: 2.6875rem;
  text-transform: uppercase;
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
  font-weight: $font-regular;
  font-family: $font-family-opensans;
  margin-left: 1.125rem;
 }
 p.desc.home.mb-home {
  padding-top: 0.625rem;
  font-size: 0.875rem;
  font-family: $font-family-opensans;
  font-weight: $font-regular;
  color: $clr-text-ten;
  line-height: 1.25rem;
  margin-left: 0.9375rem;
  margin-bottom: 0rem;
 }
 /*footer menu*/
 ul.nav.flex-column.faq_content li a {
  color: $clr-white;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0.1875rem 1.25rem;
 }
 ul.get-in-touch-list.faq_content li a {
  color: $clr-white;
  font-size: 0.875rem;
  line-height: 1.5rem;
 }
 ul.get-in-touch-list.faq_content {
  color: $clr-white;
  font-size: 0.875rem;
 }
 ul.get-in-touch-list.faq_content p {
  color: $clr-white;
  font-size: 0.875rem;
  line-height: 1.5rem;
 }
 summary.faq_title {
  font-size: 0.9375rem;
  font-family: "Raleway", sans-serif;
  font-weight: $font-bold;
  color: $clr-white;
 }
 .faq_item {
  margin-top: 0.625rem;
 }
 span.follow-us {
  font-weight: $font-bold;
  font-size: 1rem;
  margin-right: 0.625rem;
  color: $clr-white;
 }
 .get-in-touch-list li span {
  font-size: 0.875rem;
  color: $clr-white;
  padding: 0.1875rem 0.1875rem 0.1875rem 1.25rem;
 }
 .main-footer .footer-widget {
  margin-left: 0.9375rem;
  margin-bottom: 1.25rem !important;
 }
 .mobile-logo-footer {
  margin-top: -0.9375rem;
  width: 8.125rem !important;
 }
 .list-link li {
  margin-bottom: 0rem !important;
  margin-left: 1.125rem;
 }
 ul.list-link {
  margin-top: 0.5rem;
 }
 a.icofont-ui-call {
  padding: 0.5rem !important;
 }
 a.icofont-envelope {
  padding: 0.5rem !important;
 }
 .sec-title.broker-title h2 {
  font-size: 1.75rem !important;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
 }
 .vline2 {
  height: 1.875rem !important;
  margin-top: 0.1875rem !important;
 }
 .broker-logos img {
  width: 8.125rem;
 }
 .broker-section {
  padding-bottom: 1.875rem !important;
 }
 .invest-title.mb-invest-title h2 {
  line-height: 2rem;
  margin: 1.875rem 0em 0;
  font-size: 1.75rem;
 }
 .invest-title.mb-invest-title p {
  line-height: 1.75rem;
  margin: 0.625rem 0em 2.5rem;
  font-size: 1.3125rem;
 }
 .publish-title.mb-publish-title h2 {
  line-height: 2rem;
  margin: 1.875rem 0.9375rem 0;
  font-size: 1.75rem;
  text-align: center;
 }
 .publish-title.mb-publish-title p {
  margin: 0.875rem 0 0rem;
  text-align: center;
  line-height: 1.1;
  padding: 10px;
  font-size: 1.2rem;
 }
 p.publish-btn {
  margin: 1.875rem 0 1.875rem !important;
 }
 p.btn-p.mbbtn-invest {
  margin: -1.25rem 0em 1.875rem;
 }
 .testimonial-block .inner-box.mb-home-advisor {
  width: 20.625rem;
  margin: 0 0;
  padding: 0.9375rem 0.625rem;
  height: 11.25rem;
 }
 .vline {
  left: 30% !important;
  height: 9.375rem !important;
 }
 .portlogo-resize {
  width: 30% !important;
 }
 h2.home-top-invest.mb-hti {
  margin-top: 2.8125rem !important;
 }
 .sec-title.broker-title {
  margin-bottom: -0.625rem;
 }
 .sec-title.mb-sec-title {
  margin-bottom: -0.625rem;
 }
 .testimonial-block.mb-testimonial-block {
  margin-bottom: 1.25rem;
 }
 h2.port-heading {
  font-size: 1.125rem !important;
  line-height: 1.375rem !important;
 }
 .portfolio-section {
  padding-bottom: 1.25rem !important;
 }
 .invest-port {
  margin-bottom: -3.5em !important;
 }
 p.port-desc.home-port-desc {
  font-size: 0.875rem !important;
  -webkit-line-clamp: 4 !important;
 }
 .w-320 {
  height: 10rem !important;
  overflow: hidden !important;
 }
 .publish-port {
  border-right: 0rem solid $clr-white !important;
  width: 100% !important;
 }
 .distributor-port {
  width: 100% !important;
 }
 h2.font1 {
  font-size: 0.875rem !important;
 }
 .mb-conven {
  padding: 0 0.9375rem 0 0.9375rem;
 }
 .icon-padd1.mb-icon-padd1 {
  padding-left: 1.125rem;
 }
 .icon-padd2.mb-icon-padd2 {
  padding-left: 0;
 }
 .icon-img.mb-icon-img {
  padding-top: 0.75rem;
 }
 img.icon-line.mb-icon-line {
  width: 0.25rem !important;
  height: 1.4375rem;
  margin-top: -0.75rem;
 }
 .con-div.mb-con-div {
  padding-top: 1.875rem;
 }
 .modal-main-front.popup-submit {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0rem !important;
  max-height: 100% !important;
 }
 .signup-container {
  padding: 0 1.25rem !important;
 }
 svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.mb-icon.passwordicon-front {
  margin: 0rem 0rem 0rem 0rem !important;
 }
 svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.mb-icon.passwordicon-top-front.mb-passtf {
  margin: 0.625rem 0rem 0rem 0rem !important;
 }
 a.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineNone.fplink {
  margin: 0 -1.375rem;
  font-size: 0.8125rem !important;
 }
 .btn {
  margin: 0.625rem 0.9375rem 0.625rem !important;
 }
 // img.mb-gwlogo {
 // 	width: 9.375rem;
 // }

 img.mb-dlogo.deseo-logo-popup {
  width: 27%;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.signup-logo {
  margin-top: 4.0625rem !important;
  margin-left: 0rem !important;
 }
 .cancel-mar {
  margin: 0.5rem 0.5rem 0rem 0 !important;
 }
 h2.sign-heading-front {
  font-size: 1.25rem !important;
  padding-top: 0.625rem !important;
 }
 h2.get-started-front {
  font-size: 1rem !important;
 }
 .account-text-front {
  font-size: 0.9375rem !important;
 }
 p.term-text-front {
  font-size: 0.9375rem !important;
  margin-left: 0 !important;
 }
 .term-policy-link {
  font-size: 0.9375rem !important;
 }
 button.btn.btn-1.hover-filled-slide-right.btn-mobile-front.mb-otp {
  line-height: 0.9375rem;
  margin: 1.5625rem 0rem 0 0 !important;
 }
 .mobile-ml {
  margin: 0 auto !important;
 }
 button.btn.btn-1.hover-filled-slide-right.margin-top {
  margin-top: 1.5625rem !important;
 }
 .mbverify-cont.mbverify-mobile {
  width: calc(100% + 0.9375rem) !important;
 }
 .otpinput-front input {
  margin: 0.3125rem 0.1875rem 0.3125rem !important;
  padding: 0.3125rem 0.3125rem 0.3125rem !important;
 }
 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardSuccess.MuiAlert-standard.mobile-msg {
  width: 89%;
 }
 button.btn.btn-1.hover-filled-slide-right.btn-mobile-front.mb-verify {
  line-height: 0.9375rem;
  margin: 0 0 0 0.3125rem !important;
 }
 button.cancel-btn.mv-back.mobile {
  margin: -1.25rem 0rem 0 !important;
 }
 .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.mb-ccode
  input.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall {
  padding: 0.53125rem 0.3125rem;
 }
 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardError.MuiAlert-standard.mobile-msg {
  margin: 0.9375rem 0rem 0rem 0.9375rem !important;
  width: auto !important;
 }
 svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.backicon {
  font-size: 1.3125rem;
 }
 .MuiGrid-root.inner-box.portfolio.mb-portfolio {
  margin: 0rem 0.9375rem;
  padding: 0.9375rem 0rem 0;
 }
 .port-risk-img.mb-port-risk-img {
  margin-top: -0.5rem !important;
 }
 .portfolio-details h2.port-heading.mb-port-heading {
  font-size: 1.0625rem !important;
  line-height: 1.5rem !important;
  margin-top: 0.3125rem !important;
 }
 .portfolio-details.mb-portfolio-details {
  height: auto !important;
  padding: 0.3125rem 0.9375rem;
 }
 .portfolio-border {
  margin: 0.3125rem 0 0.625rem !important;
 }
 .MuiGrid-root.inner-box.portfolio.mb-portfolio {
  min-height: 16.5625rem;
  overflow: scroll;
 }
 p.portfolio-heading {
  font-size: 0.875rem !important;
 }
 p.port-desc.advisor-name {
  font-size: 0.875rem !important;
  margin-left: 1.125rem !important;
 }
 .portfolio-border-top p.port-desc {
  line-height: 1.25rem !important;
  margin-left: 0.625rem !important;
  font-size: 0.875rem !important;
 }
 .text-image.mb-text-image {
  left: 0.9375rem;
  font-size: 0.8125rem;
  top: 0.8125rem;
 }
 p.portfolio-date {
  margin-left: 0.9375rem !important;
 }
 .graph-icon {
  margin: 0.5rem 0rem 0 !important;
 }
 p.portheading.mb-portheading {
  margin-top: 6.8em;
  font-size: 1.5rem;
  padding: 0 0.625rem;
 }
 span.arrow.ti-angle-left {
  font-size: 1rem !important;
  margin-left: 0.75rem;
 }
 .btn-style-one {
  font-size: 1rem !important;
 }
 .portdetail-gradients.mb-portdetail-gradients {
  padding: 1.5625rem 0.9375rem 1.5625rem 0.9375rem;
  margin: 1.25rem 0rem 0 0rem !important;
 }
 .gradient-grid.desktop-hide p {
  width: 100%;
  position: absolute;
 }
 p.portfoliodetails-heading {
  font-size: 1.125rem !important;
  margin: 0rem 1.25rem 0 0.875rem !important;
 }
 .portnamegradauto.mb-portnamegradauto {
  margin-top: 10.5em !important;
 }
 .mobile-hide {
  display: none;
 }
 .mobile-hide.mb-dview {
  display: none;
 }
 .desktop-hide {
  display: block !important;
 }
 .desktop-hide.mb-hview {
  display: block !important;
 }
 p.advisor-info.mb-advisor-info.advisor-info-details {
  font-size: 0.875rem;
 }
 .advisortop.mb-advisortop {
  margin-top: 10.5em !important;
 }
 p.advisorhead1.mb-advisorhead1 {
  font-size: 1rem;
  margin-left: 0.875rem;
  line-height: 1.4375rem;
  margin-bottom: 0.625rem;
 }
 span.arrow.ti-angle-left.advisorarrow.mb-adarrow {
  margin-left: 0.625rem;
  font-size: 1rem !important;
 }
 .advisor-logo.mb-advisor-logo {
  border-radius: 1.5625rem;
  width: 5.9375rem;
  height: 5.3125rem;
 }
 a.advisor-social-icons.mb-advisor-social-icons {
  padding: 0.3125rem;
  font-size: 1rem;
  margin: 0 0.8125rem 0.25rem -0.3125rem !important;
  display: inline-block;
 }
 h2.wip-text {
  font-size: 1.5rem !important;
  margin-left: 0.9375rem;
 }
 .MuiGrid-root.MuiGrid-item.wip-div2 {
  margin-top: 8em !important;
  margin-left: 0 !important;
 }
 .MuiGrid-root.MuiGrid-item.wip-div {
  margin-top: 2.9375rem !important;
  margin-left: -7em !important;
 }
 .mobile-w100 {
  width: 100%;
  text-align: left !important;
 }
 .advisor-logo-width.mb-advisor-logo-width {
  width: 8%;
  padding-left: 0.9375rem;
 }
 .grid10-width.mb-grid10-width {
  width: 61%;
  margin-left: 6em !important;
  padding-left: 0.625rem;
 }
 .advisor-tab-width.mb-advisor-tab-width {
  width: 100%;
 }
 p.advisorhead3.mb-advisorhead3 {
  font-size: 1.375rem;
  line-height: 1.375rem;
 }
 .advisor-social-icons {
  padding-bottom: 0.625rem;
 }
 p.advisor-info.mb-advisor-info {
  text-align: left !important;
  line-height: 0.875rem;
  font-size: 0.875rem;
 }
 .MuiGrid-root.MuiGrid-item.mobile-adtop {
  margin-top: 1.25rem;
  padding: 0 0.9375rem;
 }
 .text-image-details.mb-tid {
  font-size: 0.75rem;
  line-height: 2.6 !important;
  margin-left: 0.9375rem;
 }
 p.portdetails-date.mb-date {
  margin-left: 0.4rem !important;
  margin-top: -4px !important;
 }
 .border-bottom2.mb-border-bottom2 {
  border-bottom: 0rem solid $clr-border-three !important;
 }
 p.nostock.mb-nostock {
  margin-left: 0rem !important;
 }
 p.port-desc.port-desc-details.portdetails-investstyle {
  margin-left: -0.625rem !important;
  word-break: break-word;
 }
 .modal-main-front.popup-submit.risk-profile {
  width: 100% !important;
 }
 .border-splan.mb-border-splan {
  width: 90%;
 }
 .sub-plan.mb-sub-plan {
  padding: 0.5rem 0 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
 }
 .form-group-checkbox.mb-checkbox label:before {
  margin-top: 1.125rem;
 }
 .form-group-checkbox.mb-checkbox input:checked + label:after {
  top: 0.8125rem;
 }
 .plan-price {
  margin: 0.625rem 0.3125rem 0rem 0 !important;
 }
 p.rightarrow {
  display: none;
 }
 p.portfolio-heading.port-advisor.text-right {
  text-align: left !important;
 }
 p.port-desc.port-desc-details {
  font-size: 0.875rem !important;
 }
 .port-advisor-border {
  border-bottom: 0rem solid rgba(255, 255, 255, 0.79) !important;
 }
 .modisplay-border {
  border-top: 0.0625rem solid $clr-border-three;
  padding: 0.5rem 0rem 0.25rem;
  margin-bottom: 0.125rem !important;
 }
 p.port-advisor {
  line-height: 1.25rem !important;
 }
 .pleft-20 {
  padding-left: 0 !important;
  margin-bottom: -1.25rem !important;
 }
 p.port-desc1 {
  font-size: 0.875rem !important;
  line-height: 1.375rem !important;
 }
 p.port-desc.port-desc-details.port-advisor-border.lineheight.mb-alh {
  line-height: 1.375rem !important;
 }
 .MuiGrid-root.MuiGrid-item.d-flex-portinfo.mt35 {
  margin-top: 1.5625rem !important;
 }
 .mbgraph-display {
  display: inline-block !important;
  padding: 0 0.9375rem;
 }
 .border-right {
  border-right: 0rem solid #505050 !important;
 }
 h4.portdetails-question {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
 }
 p.portdetails-answer {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  margin-bottom: 1.25rem !important;
 }
 p.portdetails-answer p {
  line-height: 1.25rem;
 }
 .pl-40 {
  padding-left: 0rem !important;
 }
 .graph-img {
  padding-left: 0.9375rem !important;
 }
 .MuiGrid-root.bg-gray.port-graph-bottom.mb-pgb {
  padding-bottom: 0 !important;
 }
 h2.risk-profile-ques {
  font-size: 1.25rem !important;
  margin-top: 3.375rem !important;
 }
 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardError.MuiAlert-standard.mbdisplay-error {
  margin: 0.9375rem 0rem 0rem 0.5rem !important;
  width: 100% !important;
 }
 button.cancel-btn.mv-back.subplanarrow {
  margin: -10px 0rem !important;
 }
 .actual-price {
  margin-top: 0.9375rem;
 }
 .account-bal {
  text-align: left;
  line-height: 1.125rem;
  font-size: 0.875rem;
  margin-bottom: 1.25rem;
 }
 .portfolio-sec-details.mb-psd {
  padding-bottom: 0 !important;
 }
 .modal-front {
  background: rgb(255, 255, 255);
 }
 input.search-portfolio.mb-search-portfolio {
  margin-top: 0.9375rem;
 }
 input.search-portfolio.mb-port-search {
  margin-left: -14rem;
  margin-top: 4rem;
 }
 button.search-icon.mb-search-icon {
  left: 21%;
 }
 .home-about-slider .carousel .control-dots {
  bottom: 3.75rem !important;
 }
 p.term-desc {
  padding: 0.9375rem 0.9375rem 0 !important;
  font-size: 0.9375rem !important;
 }
 .sec-title.term h2 {
  margin-top: 0.9375rem !important;
 }
 ol.ol-type-number {
  margin-left: 3.875rem !important;
 }
 ol.ol-type-number > li:before {
  border-radius: 0.75rem;
 }
 .term ol.ol-type-small {
  padding: 0 0.9375rem 0 0rem !important;
  font-size: 0.9375rem !important;
  margin-left: 1.625rem !important;
 }
 ol.ol-type-number {
  font-size: 1.0625rem !important;
 }
 .term ol.ol-type-roman {
  font-size: 0.9375rem !important;
  margin-left: 2.125rem !important;
 }
 ol.ol-type-number1 {
  margin-left: 1.6875rem !important;
  font-size: 0.9375rem !important;
  margin-top: 0.75em !important;
 }
 .portfolio-section.mb-loader,
 .portfolio-section.loader-popup.mb-loader {
  top: 7%;
  left: 0%;
  width: 100%;
  height: 100%;
 }
 .container-loader.mb-loader {
  top: 46%;
  left: 48%;
 }
 h2.question-risk {
  font-size: 1rem;
  margin: 1.25rem 1.25rem 0.9375rem;
 }
 .MuiFormGroup-root.text-left.css-dmmspl-MuiFormGroup-root {
  margin: 0rem 1.25rem;
 }
 .ipad-wip {
  margin: 3.75rem 0 9.375rem;
 }
 .advisorarrow.mb-adarrow {
  width: 1.4375rem;
 }
 .portdetailsarrow {
  width: 1.5625rem !important;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.advisorsearch {
  margin-left: -2.75rem;
  margin-bottom: -1.25rem;
  margin-top: 1.25rem;
 }
 .sec-title.mbpublisher {
  margin-top: 9.5em;
 }
 .sec-title.mb-sec-title.mbpublisher h2 {
  line-height: 1.25rem !important;
  font-size: 1.125rem !important;
  color: $clr-hyperlink;
  font-weight: $font-semibold !important;
 }
 .sec-title .title.mb-publisher {
  line-height: 1.25rem;
  text-transform: none;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
 }
 h2.portfolio-steps.mb-portfolio-steps {
  font-size: 1.125rem;
  line-height: 1.25rem;
  margin: 1.5625rem 0 -1.75rem;
  color: $clr-hyperlink;
  font-weight: $font-bold;
 }
 h4.stephead {
  font-size: 0.8125rem !important;
  line-height: 1.125rem !important;
 }
 p.steptext {
  line-height: 1.125rem !important;
  font-size: 0.8125rem !important;
  margin-top: 1.25rem !important;
 }
 h2.invest-app.mbhtb {
  line-height: 1.25rem !important;
  font-size: 1.125rem !important;
  margin-top: 1.5625rem;
  color: $clr-hyperlink;
  font-weight: $font-bold !important;
 }
 .tab-indicator {
  margin-top: 20px;
 }
 button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.tab-head {
  width: 36% !important;
  font-size: 0.8125rem !important;
  margin-top: 1.25rem;
 }
 p.invest-text {
  font-size: 0.875rem !important;
  margin: 1.25rem;
  line-height: 1.25rem !important;
 }
 .mb-inblock.mbib {
  display: inline-block;
 }
 p.tab-text.mb-tab-text {
  line-height: 1.25rem;
  font-size: 0.8125rem;
  margin-top: 0rem;
 }
 .tabimg1 {
  text-align: center !important;
 }
 .tabimg2 {
  text-align: center !important;
 }
 .tabimg3 {
  text-align: center !important;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.text-right.tabimg1.css-1fyyp8j-MuiGrid-root img {
  width: 70%;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.text-right.tabimg2.css-1fyyp8j-MuiGrid-root img {
  width: 70%;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.text-right.tabimg3.css-1fyyp8j-MuiGrid-root img {
  width: 70%;
 }
 .mb-gsbtn {
  margin-top: 0.625rem !important;
 }
 label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.question-label {
  font-size: 0.875rem !important;
  margin-left: -0.625rem !important;
 }
 p.note {
  margin: 1.5625rem 0 -0.9375rem;
 }
 button.account-value {
  padding: 0.1875rem 0 0.1875rem 0.5625rem;
  font-size: 0.875rem;
 }
 .cross-font {
  font-size: 0.625rem;
 }
 p.invest-head {
  font-size: 0.8125rem !important;
 }
 p.invest-subhead {
  padding: 0 0.625rem 0 0 !important;
  font-size: 0.8125rem !important;
 }
 p.account-bal.mb-acbal {
  text-align: center;
  margin-left: 0.3125rem;
 }
 p.account-value.sell {
  left: 1.1875rem;
 }
 p.account-value.buy {
  left: 1.1875rem;
 }
 .mb-sellab {
  margin-left: 1.25rem;
 }
 .portfolio-filter {
  margin-top: 12em;
  margin-bottom: 0.9375rem;
  padding: 0rem 1.5625rem;
 }
 .dm-mobile-hide {
  display: none;
 }
 .dm-desktop-hide {
  display: block !important;
 }
 .HomeImg {
  content: url("../../images/home.png");
  margin-left: 0rem;
  width: 1.25rem;
  margin-top: 0.1875rem;
 }
 .active .HomeImg {
  content: url("../../images/home.png");
  margin-left: 0rem;
  width: 1.25rem;
 }
 .PortfolioImg {
  content: url("../../images/portfolio.png");
  margin-left: 0rem;
  width: 1.25rem;
 }
 .active .PortfolioImg {
  content: url("../../images/portfolio.png");
  margin-left: 0rem;
  width: 1.25rem;
 }
 .OrderBookImg {
  content: url("../../images/order-book.png");
  margin-left: 0rem;
  width: 1.25rem;
  margin-top: 0.1875rem;
 }
 .active .OrderBookImg {
  content: url("../../images/order-book.png");
  margin-left: 0rem;
  width: 1.25rem;
 }
 .SubscriptionImg {
  content: url("../../images/subscription.png");
  margin-left: 0rem;
  width: 1.125rem;
  margin-top: -0.3125rem;
 }
 .active .SubscriptionImg {
  content: url("../../images/subscription.png");
  margin-left: 0rem;
  width: 1.125rem;
 }
 .RiskProfileImg {
  content: url("../../images/risk-profile.png");
  margin-left: 0rem;
  width: 1.5rem;
  margin-top: 0.0625rem;
 }
 .active .RiskProfileImg {
  content: url("../../images/risk-profile.png");
  margin-left: 0rem;
  width: 1.5rem;
 }
 .inner-box.portfolio.dashboard-port.mb-dashboard-port {
  width: 17.1875rem;
  margin: 0rem 0.625rem 0;
  min-height: 29.0625rem;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.dashboard-top.mb-dashboard-top {
  flex-wrap: inherit !important;
  display: flex;
  margin-top: 6em !important;
 }
 button.theme-btn.btn-style-one.get-started.btn-w160 {
  padding: 0.5625rem 0.3125rem !important;
 }
 .btn-w160 {
  width: 7.1875rem !important;
 }
 p.port-desc.advisor-name.dashboard.mb-dashboard {
  margin-left: 1.875rem !important;
  margin-top: -0.125rem !important;
  font-size: 0.875rem !important;
 }
 p.portfolio-date.dashboard.mb-dashboard {
  margin-left: -0.75rem !important;
 }
 .MuiGrid-root.head-border-port.portfolio-border.dashboard-border {
  margin-top: -1.125rem !important;
 }
 a.nav__link.homedash {
  margin-top: -0.1875rem;
 }
 main.dashboard-scroll.mb-dashboard-scroll.MuiBox-root {
  overflow-y: unset;
  overflow-x: scroll;
  /* max-height: 35.3125rem;
		min-height: 35.3125rem; */
  background-color: $clr-white;
  /* height: 100vh; */
  min-height: 90vh;
 }
 .scrollbar-custom::-webkit-scrollbar {
  height: 0.25rem;
  background-color: $clr-bg-scrollbar;
  border-radius: 0.625rem;
 }
 .portfolio-border-top p.port-desc.dashboard.mb-dashboard {
  margin-left: 0.3125rem;
 }
 /* .order-subborder{display: grid;float: left;}.order-headborder{display: grid;float: left;} */
 .MuiGrid-root.inner-box.portfolio.dashboard-port.mb-dashboard-port.mobile-apierror {
  width: 19.375rem !important;
 }
 h2.apierror {
  font-size: 1.125rem !important;
 }
 img.api-error.mb-aeimg {
  width: 33% !important;
  margin-top: 5em;
 }
 img.img-width {
  width: 100% !important;
 }
 .mobile-obs {
  display: flex;
 }
 .order-headborder.mobile-obs {
  display: inline-grid;
  border-bottom: 0rem solid $clr-text-eight;
  background-color: $clr-text-eight;
  box-shadow: $clr-box-shadow;
 }
 .order-subborder.mobile-obs {
  display: inline-grid;
  border-bottom: 0rem solid $clr-text-eight;
 }
 .order-headborder.mobile-obs p.portfolio-heading.port-advisor.mobile-obs {
  border-bottom: 0.1rem solid $clr-text-eight;
  padding: 0.9375rem 0.9375rem 1.25rem;
  margin: 0.3125rem 0 0rem;
 }
 .order-headborder.mobile-obs p.portfolio-heading.port-advisor.mobile-obs.lastbn {
  border-bottom: none;
 }
 .order-subborder.mobile-obs p.order-subhead.mobile-obs {
  border-bottom: 0.1rem solid $clr-text-eight;
  padding: 0.25rem 0.9375rem 1.1875rem;
  margin: 0.9375rem 0 0rem;
 }
 .order-subborder.mobile-obs p.order-subhead.mobile-obs.lastbn {
  border-bottom: none;
 }
 p.portfolio-heading.port-advisor.mobile-obs {
  width: 8.4375rem;
 }
 p.order-subhead.mobile-obs {
  width: 8.4375rem;
 }
 .mb-orderra {
  text-align: right !important;
  padding: 0.625rem 0.3125rem !important;
 }
 .MuiGrid-root.theme-btn.btn-style-one.get-started.login-letter {
  position: relative;
  line-height: 1;
  overflow: hidden;
  text-transform: none;
  border-radius: 1.875rem;
  background-color: $clr-green;
  color: $clr-white;
  width: auto;
  padding: 0.4375rem 1.125rem;
  display: inline-grid;
  font-weight: $font-bold;
  font-size: 1.125rem !important;
  font-family: $font-family-opensans;
  cursor: pointer;
  margin: 1rem 0.625rem;
 }
 li.theme-btn.btn-style-one.get-started.signoutbtn {
  position: relative;
  line-height: 1;
  overflow: hidden;
  text-transform: none;
  border-radius: 1.875rem;
  background-color: $clr-white;
  color: $clr-pink;
  padding: 0rem 1.25rem !important;
  display: inline-grid;
  font-weight: $font-bold;
  font-size: 1rem !important;
  font-family: $font-family-opensans;
  cursor: pointer;
  width: 100%;
 }
 h1.pnf-text > span {
  width: 3.125rem;
 }
 h1.pnf-text {
  font-size: 4rem !important;
  height: auto !important;
 }
 h2.pnf-text {
  font-size: 1.125rem !important;
 }
 p.pnf-text {
  font-size: 0.875rem !important;
 }
 .portfolio-section.pnf {
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
 }
 .portfolio-section.pnf .portfolio-details.dashboard {
  height: auto;
 }
}
@media (min-width: 384px) and (max-width: 538px) {
 .invest-container {
  margin-bottom: 0em !important;
 }
 .footer-widget.links-widget p {
  line-height: 2;
  font-size: 14px;
 }
 .foot-logo {
  margin-left: 15px;
 }
 .main-footer {
  padding-top: 1em !important;
 }
 .footer-details .col-lg-4.l-float {
  width: 100% !important;
 }
 #menu {
  margin: -4.375rem 0 0 -22em !important;
  width: 105%;
 }
 .otpinput-front input {
  margin: 0.3125rem 0.3125rem 0.3125rem !important;
 }
 li a.theme-btn.btn-style-one.get-started {
  width: 19.6875rem !important;
 }
 .about-column.home-port.con-div.mb-condiv {
  margin-left: 2rem;
 }
 .MuiGrid-root.inner-box.portfolio.mb-portfolio {
  margin: 0rem 2rem;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-6.col-lg-6.l-float.distributor-port
  .MuiGrid-root.auto-container {
  padding: 0 0.9375rem;
 }
 .portfolio-filter {
  padding: 0 1.25rem;
 }
 input.search-portfolio.mb-search-portfolio {
  margin-top: 0.9375rem;
 }
 input.search-portfolio.mb-port-search {
  margin-left: -14rem;
  margin-top: 4rem;
 }
 button.search-icon.mb-search-icon {
  left: 29%;
 }
 .portfolio-section.loader.mb-loader,
 .portfolio-section.loader-popup.mb-loader {
  top: 7%;
  left: 0%;
  width: 100%;
  height: 100%;
 }
 .container-loader.mb-loader {
  top: 46%;
  left: 48%;
 }
 h2.question-risk {
  font-size: 1rem;
  margin: 1.25rem 1.25rem 0.9375rem;
 }
 .MuiFormGroup-root.text-left.css-dmmspl-MuiFormGroup-root {
  margin: 0rem 1.25rem;
 }
 .ipad-wip {
  margin: 3.75rem 0 9.375rem;
 }
 .advisorarrow.mb-adarrow {
  width: 1.4375rem;
 }
 .portdetailsarrow {
  width: 1.5625rem !important;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.advisorsearch {
  margin-left: -2.75rem !important;
  margin-bottom: -1.25rem;
  margin-top: 1.25rem;
 }
 label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.question-label {
  font-size: 0.875rem !important;
  margin-left: -0.625rem !important;
 }
 p.note {
  margin: 1.5625rem 0 -0.9375rem;
 }
 button.account-value {
  padding: 0.1875rem 0 0.1875rem 0.5625rem;
  font-size: 0.875rem;
 }
 .cross-font {
  font-size: 0.625rem;
 }
 p.invest-head {
  font-size: 0.8125rem !important;
 }
 p.invest-subhead {
  padding: 0 0.625rem 0 0 !important;
  font-size: 0.8125rem !important;
 }
 p.account-value.sell {
  left: 1.8125rem;
 }
 p.account-value.buy {
  left: 1.8125rem;
 }
 .dm-mobile-hide {
  display: none;
 }
 .dm-desktop-hide {
  display: block !important;
 }
 .portfolio-border-top p.port-desc.dashboard.mb-dashboard {
  margin-left: 0.3125rem;
 }
}
@media (min-width: 412px) and (max-width: 538px) {
 .invest-container {
  margin-bottom: 0em !important;
 }
 .footer-widget.links-widget p {
  line-height: 2;
  font-size: 14px;
 }
 .foot-logo {
  margin-left: 15px;
 }
 .main-footer {
  padding-top: 1em !important;
 }
 .footer-details .col-lg-4.l-float {
  width: 100% !important;
 }
 #menu {
  margin: -4.375rem 0 0 -23em !important;
  padding-top: 6.875rem !important;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-6.col-lg-6.l-float.distributor-port
  .MuiGrid-root.auto-container {
  padding: 0 1.375rem;
 }
 .sec-title h2 {
  margin-left: 0rem;
  padding: 0 0.9375rem;
 }
 .about-column.home-port.con-div.mb-condiv {
  margin-left: 2.5625rem;
 }
 .MuiGrid-root.inner-box.portfolio.mb-portfolio {
  margin: 0rem 2.5625rem;
 }
 .otpinput-front input {
  margin: 0.3125rem 0.4375rem 0.3125rem !important;
 }
 .portfolio-section.loader.mb-loader,
 .portfolio-section.loader-popup.mb-loader {
  top: 7%;
  left: 0%;
  width: 100%;
  height: 100%;
 }
 .container-loader.mb-loader {
  top: 46%;
  left: 48%;
 }
 .publish-title h2 {
  margin: 1.875rem 1.3125rem 0;
 }
 h2.question-risk {
  font-size: 1rem;
  margin: 1.25rem 1.25rem 0.9375rem;
 }
 .MuiFormGroup-root.text-left.css-dmmspl-MuiFormGroup-root {
  margin: 0rem 1.25rem;
 }
 .ipad-wip {
  margin: 3.75rem 0 9.375rem;
 }
 .advisorarrow.mb-adarrow {
  width: 1.4375rem;
 }
 .portdetailsarrow {
  width: 1.5625rem !important;
 }
 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.advisorsearch {
  margin-left: -3.5rem !important;
  margin-bottom: -1.25rem;
  margin-top: 1.25rem;
 }
 label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.question-label {
  font-size: 0.875rem !important;
  margin-left: -0.625rem !important;
 }
 p.note {
  margin: 1.5625rem 0 -0.9375rem;
 }
 button.account-value {
  padding: 0.1875rem 0 0.1875rem 0.5625rem;
  font-size: 0.875rem;
 }
 .cross-font {
  font-size: 0.625rem;
 }
 p.invest-head {
  font-size: 0.8125rem !important;
 }
 p.invest-subhead {
  padding: 0 0.625rem 0 0 !important;
  font-size: 0.8125rem !important;
 }
 p.account-value.sell {
  left: 2rem;
 }
 p.account-value.buy {
  left: 2rem;
 }
 .dm-mobile-hide {
  display: none;
 }
 .dm-desktop-hide {
  display: block !important;
 }
 .portfolio-border-top p.port-desc.dashboard.mb-dashboard {
  margin-left: 0.3125rem;
 }
}
/*End Media Query for mobile responsive*/
