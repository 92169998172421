/* loader css */

.container-loader {
	position: absolute;
	width: 7.5rem;
	height: 3.125rem;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 111111;
	font-weight: $font-black;
	font-family: $font-family-opensans;
	font-size: 2.8125rem;
	color: $clr-white;
}

.container-loader>div {
	position: absolute;
	transform-origin: center;
}

.l {
	left: 0rem;
}

.i {
	left: 4.0625rem;
}

.n {
	left: 5.125rem;
}

.g {
	left: 7.5rem;
}

.square,
.circle,
.triangle {
	left: 1.875rem;
}

.square {
	background: $clr-hyperlink;
	width: 1.9375rem;
    height: 1.9375rem;
    left: 1.875rem;
    top: -0.125rem;
	transform: scale(0);
	-webkit-animation: shrinkgrow 3s ease-in-out infinite;
	animation: shrinkgrow 3s ease-in-out infinite;
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

.popupLoader .square {
	top: 1.125rem;
}

.circle {
	background: $clr-green;
	width: 2.0625rem;
	height: 2.0625rem;
	top: -0.1875rem;
	left: 1.875rem;
	border-radius: 50%;
	-webkit-animation: shrinkgrow 3s ease-in-out infinite;
	animation: shrinkgrow 3s ease-in-out infinite;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

.popupLoader .circle {
	top: 1.1875rem;
}

.triangle {
	width: 0;
	height: 0;
	left: 1.875rem;
	top: -0.0625rem;
	border-style: solid;
	border-width: 0rem 1.03125rem 1.875rem 1.03125rem;
	border-color: transparent transparent $clr-pink transparent;
	transform: scale(0);
	-webkit-animation: shrinkgrow 3s ease-in-out infinite;
	animation: shrinkgrow 3s ease-in-out infinite;
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.popupLoader .triangle {
	top: 1.0625rem;
}

.portfolio-section.loader {
	background: $clr-bg-main;
    position: fixed;
    top: -50%;
    left: 0;
    width: 100vw;
    height: 100vw;
    z-index: 99999999;
    transition: opacity .3s;
}

/*loader popup*/
.portfolio-section.loader-popup {
    /* background: $clr-bg-main; */
	background: rgba(42, 36, 46, 0.82);
    position: fixed;
    top: -50%;
    left: 0;
    width: 100vw;
    height: 100vw;
    z-index: 99999999;
    transition: opacity .3s;
}
.circle-popup{
	background: $clr-green;
    width: 2.0625rem;
    height: 2.0625rem;
    top: 1.125rem;
    left: 1.875rem;
    border-radius: 50%;
    animation: shrinkgrow 3s ease-in-out infinite;
    animation-delay: 0s;
}
.square-popup {
    background: $clr-hyperlink;
    width: 1.9375rem;
    height: 1.9375rem;
    left: 1.875rem;
    top: 1.25rem;
    transform: scale(0);
    animation: shrinkgrow 3s ease-in-out infinite;
    animation-delay: 2s;
}
.triangle-popup {
    width: 0;
    height: 0;
    left: 1.875rem;
    top: 1.25rem;
    border-style: solid;
    border-width: 0rem 1.03125rem 1.875rem 1.03125rem;
    border-color: transparent transparent $clr-pink transparent;
    transform: scale(0);
    animation: shrinkgrow 3s ease-in-out infinite;
    animation-delay: 1s;
}
/*loader popup*/


@-webkit-keyframes shrinkgrow {
	0% {
		transform: scale(0);
	}

	12.5% {
		transform: scale(1);
	}

	25% {
		transform: scale(1);
	}

	33% {
		transform: scale(0);
	}

	100% {
		transform: scale(0);
	}
}

@keyframes shrinkgrow {
	0% {
		transform: scale(0);
	}

	12.5% {
		transform: scale(1);
	}

	25% {
		transform: scale(1);
	}

	33% {
		transform: scale(0);
	}

	100% {
		transform: scale(0);
	}
}

/* loader css */