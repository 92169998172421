.carousel-root.home-about-slider .carousel .slider-wrapper {
 width: 100% !important;
}

.carousel-root.home-about-slider .carousel.carousel-slider .control-arrow:hover {
 background: transparent !important;
}

.carousel-root.home-about-slider .carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
 border-top: 0 !important;
 border-right: 0 !important;
 width: 1.125rem;
 height: 1.125rem;
 border-color: $clr-white !important;
}

.carousel-root.home-about-slider .carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
 opacity: 1;
}

.carousel-root.home-about-slider .carousel .control-next.control-arrow:before {
 transform: rotate(-135deg);
 border-bottom: 0.25rem solid !important;
 border-left: 0.25rem solid !important;
 color: $clr-white !important;
}

.carousel-root.home-about-slider .carousel .control-prev.control-arrow:before {
 transform: rotate(45deg);
 border-bottom: 0.25rem solid !important;
 border-left: 0.25rem solid !important;
 color: $clr-white;
}

.carousel-root.home-about-slider .carousel.carousel-slider .control-arrow {
 padding: 1.25rem !important;
 margin-top: 2em;
}

.carousel .control-prev.control-arrow:before {
 transform: rotate(135deg);
 border-bottom: 0.15rem solid $clr-bg-advisor !important;
 border-right: 0.15rem solid $clr-bg-advisor !important;
}

.carousel .control-dots {
 display: none;
}

.carousel-root.home-about-slider {
 margin-bottom: -2.5rem;
}

.home-about-slider .carousel .control-dots {
 display: none !important;
 bottom: 5rem;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
 border-top: 0 !important;
 width: 0.6875rem !important;
 height: 0.6875rem !important;
 border-color: $clr-bg-advisor !important;
}
.carousel.carousel-slider .control-arrow {
 top: -4.5em !important;
}
.carousel .slider-wrapper {
 width: 86% !important;
}

.slider-risk {
 padding: 0;
}

.carousel .control-prev.control-arrow {
 left: 3.125rem;
}
.sec-title {
 position: relative;
 margin-bottom: 3.125rem;
}

.sec-title .title {
 position: relative;
 color: $clr-text-ten;
 font-size: 0.875rem;
 line-height: 2.6875rem;
 text-transform: uppercase;
 margin-top: 3.75rem;
 margin-bottom: 3.75rem;
 font-weight: $font-regular;
 font-family: $font-family-opensans;
}
img.srh-img {
 margin-top: 0;
 width: 1.25rem !important;
}
.about-section .content-column .sec-title {
 margin-bottom: 1.5625rem;
 text-align: left;
}
.about-section .content-column {
 position: relative;
 margin-bottom: 0rem;
}

.about-section .content-column .inner-column {
 position: relative;
 padding-top: 9.0625rem;
 padding-left: 6.25rem;
}

.about-section .content-column .text {
 position: relative;
 margin-bottom: 5.3125rem;
}

.about-section .content-column .text p {
 position: relative;
 color: #666666;
 font-size: 0.875rem;
 line-height: 2em;
 margin-bottom: 1.25rem;
}

.about-section .content-column .text p:last-child {
 margin-bottom: 0rem;
}

.about-section .content-column .signature {
 position: relative;
 font-size: 1.875rem;
 font-family: "SignericaMedium";
}

.about-section .content-column .signature span {
 position: relative;
 font-weight: $font-semibold;
 color: $clr-black;
 font-size: 0.875rem;
 display: block;
 margin-top: 0.625rem;
 text-transform: uppercase;
 font-family: $font-family-opensans;
}

.MuiGrid-root.about-column.border-top {
 border-top: 0.0625rem solid $clr-bg-scrollbar !important;
}

h2.font1 {
 color: $clr-green;
 font-weight: $font-xbold;
 font-size: 2.125rem;
 font-family: $font-family-montserrat;
}

.l-float {
 float: left;
}

.con-div {
 text-align: center;
 padding-top: 3.75rem;
}

.icon-img {
 padding-top: 0.75rem;
}

.icon-padd2 {
 padding-left: 2.5rem;
}

.icon-padd1 {
 padding-left: 0.9375rem;
}

p.desc {
 padding: 5rem 0rem;
 font-size: 1.375rem;
 color: $clr-text-five;
 text-align: left;
 line-height: 1.6;
 font-weight: $font-regular;
}
p.desc.home {
 padding-top: 1.875rem;
 font-size: 1.25rem;
 font-family: $font-family-opensans;
 font-weight: $font-regular;
 color: $clr-text-ten;
 line-height: 2rem;
 margin-bottom: 0.625rem;
}
img.icon-line {
 width: 0.625rem !important;
 height: 1.4375rem;
 margin-top: 0.3125rem;
}
