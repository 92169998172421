/* advisor details*/

.advisortop {
	margin-top: 12.7em !important;
}
.advisorarrow {
	width: 3.5%;
}
span.arrow.ti-angle-left {
	font-size: 1.375rem;
	font-weight: $font-black;
	line-height: 1.75rem;
	cursor: pointer;
	color: $clr-green;
}
span.arrow.ti-angle-left.advisorarrow {
	line-height: 0;
	font-size: 1.125rem;
}
p.advisorhead1 {
	line-height: 1;
	font-family: $font-family-montserrat;
	text-transform: uppercase;
	font-weight: $font-regular;
	font-size: 1.1875rem;
	color: $clr-green;
	margin-bottom: 1rem;
}

p.advisorhead2 {
	line-height: 0.8;
	font-family: $font-family-montserrat;
	font-weight: $font-semibold;
	font-size: 1.5rem;
	margin-left: 0.3125rem;
	color: $clr-green;
	margin-bottom: 1.875rem;
}

p.advisorhead3 {
	line-height: 2;
	font-family: $font-family-montserrat;
	font-weight: $font-bold;
	font-size: 1.875rem;
	color: $clr-green;
	margin-top: 0.625rem;
	margin-bottom: -0.3125rem;
	text-transform: capitalize;
}

.advisor-logo {
	border-radius: 2.5rem;
	width: 8.4375rem;
	height: 7.1875rem;
	background-color: $clr-white;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	position: relative;
	img {
		width: 75%;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
    transform: translateY(-50%);
	}
}

.advisor-logo-width {
	width: 12.666667%;
	padding-top: 1.25rem;
}

.advisor-social-icons {
	text-align: right;
	margin-top: 1.25rem !important;
}

.border-bottom {
	border-bottom: 0.0625rem solid $clr-border-three !important;
}

.border-bottom2 {
	border-bottom: 0.0625rem solid $clr-border-three !important;
}

.border-bottom1 {
	border-bottom: 0.0625rem solid $clr-border-one !important;
}

a.advisor-social-icons {
	border: 0.0625rem solid $clr-border-four;
	border-radius: 3.125rem;
	padding: 0.5rem;
	margin-left: 0.75rem;
	color: $clr-border-four;
	font-size: 1.375rem;
	text-decoration: none;
}

p.advisor-info {
	font-size: 1rem;
	color: $clr-text-five;
	font-weight: $font-regular;
	margin: 0.625rem 0 0rem;
	font-family: $font-family-opensans;
	line-height: 1.4375rem;
}

p.advisor-info span {
	color: $clr-text-three;
	margin-left: 0.375rem;
	font-weight: $font-semibold;
	font-family: $font-family-opensans;
}

p.advisor-info.advisor-info-details {
	font-size: 0.9375rem;
	font-family: $font-family-opensans;
	line-height: 1.5625rem;
	font-weight: $font-regular;
	color: $clr-text-five;
}
.d-flex {
	display: flex;
}

.grid10-width {
	width: 83.333333%;
}

.grid4-width {
	width: 33.333333%;
}
a.advisor-social-icons:hover {
	background-color: $clr-white;
	color: $clr-green;
}
.advisor-tab-width {
	width: 33.33%
}
.margin-top-25 {
	margin-top: 1.5625rem !important;
}
.mt-20 {
	margin-top: 1.25rem !important;
}

button.mt-20 {
	margin-top: 1.25rem !important;
}
p.advisor-info ol {
	/* margin-top: -0.5rem;
    margin-bottom: -0.3125rem; */
	counter-reset: item;
	color: $clr-text-five;
	padding-left: 0;
	margin-bottom: 0;
}

p.advisor-info ol li {
	list-style-type: decimal;
	display: block;
}

p.advisor-info ol li:before {
	content: counter(item) ". ";
	counter-increment: item;
	font-weight: bold;
	color: $clr-text-five;
	width: 1.25rem;
	margin-left: 1em;
}

p.advisor-info ul {
	/* margin-top: -0.5rem;
    margin-bottom: -0.3125rem; */
	color: $clr-text-five;
	padding-left: 2rem;
	margin-bottom: 0;
}

p.advisor-info ul li {
	list-style-type: disc;
}

p.advisor-info ul li:before {
	font-weight: bold;
	color: $clr-text-five;
	width: 1.25rem;
}

p.advisor-info br:empty {
	display: none;
}

p.advisor-info:empty {
	display: none;
}
p.advisor-info.advisor-info-details p {
	margin-bottom: 0;
}
// .new-advisor-logo img {
// 	background-color: #fff;
//     border-radius: 2.5rem;
//     height: 7.1875rem;
//     width: 8.4375rem;
// }
