/* custom scrollbar */
.scrollbar-custom::-webkit-scrollbar-track {
	border-radius: 0.625rem;
	background-color: $clr-bg-scrollbar;
}

.scrollbar-custom::-webkit-scrollbar {
	width: 0.5rem;
	background-color: $clr-bg-scrollbar;
	border-radius: 0.625rem;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
	border-radius: 0.625rem;
	background-color: $clr-bg-scrollbar1;
}

/* custom scrollbar*/