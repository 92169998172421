/* abstracts files */
@import "abstracts/variables";

/* base files */
@import "base/reset";
@import "base/normalize";
@import "base/responsive";

/* components files */
@import "components/buttons";
@import "components/carousel";
@import "components/search";
@import "components/checkbox";

/* layout files */
@import "layout/header";
@import "layout/footer";
@import "layout/forms";
@import "layout/loader";
@import "layout/scrollbar";
@import "layout/sidebar";

/* pages files */
@import "pages/home";
@import "pages/portfolio";
@import "pages/portfoliodetails";
@import "pages/advisordetails";
@import "pages/termsandprivacy";
@import "pages/workinprogress";
@import "pages/404";
@import "pages/dashboard";
@import "pages/publisher";

/* vendors files */
// @import "vendors/bootstrap.min";
@import "vendors/fontawesome.min";
@import "vendors/icofont.min";
@import "vendors/themify-icons";

